@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
$newFont: 'Avenir';

.hoverState_liveClassCard{
  border: 1px solid #423DCF !important;
  border-radius: 6px !important;
  box-sizing: border-box !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.24) !important;
  cursor: pointer !important;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 8px;
  max-width: 224px;
  border: 1px solid #ebecfe;
  border-radius: 6px;
  font-family: $newFont;
  align-items: center;
  margin-left: 6px;

  @include respond(tablet) {
    max-width: 100%;
  }

  .Home_Lib_wrapper {
    max-width: 100%;
  }

  .thumbnailWrapper {
    position: relative;
    display: inline-block;
    flex: 0 0 128px;
    background: #d9d9d9;
    border-radius: 6px;
    height: 128px;
    width: 100%;
    padding: 8px;
    background: linear-gradient(253deg, #477B96 0%, #6DA5C0 97.6%);
    user-select: none;
    pointer-events: none;

    .statusBadge:not(.classLive) {
      position: absolute;
      top: -20px;
      bottom: auto;
      left: -14px;
      right: auto;
    }

    .thumbnailTitle {
      display: -webkit-box;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      word-wrap: break-word;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .thumbnailIcon {
      position: absolute;
      bottom: 11px;
      right: 11px;
    }
  }

  .home_mylib_thumbnail_offset {
    margin-top: -15px;
    background: linear-gradient(252.98deg, #477b96 0%, #6da5c0 97.6%);
  }

  .contentWrapper {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-self: flex-start;
    justify-content: space-between;
    padding-top: 12px;

    .infoWrapper {
      display: flex;
      flex-direction: column;

      .title {
        display: -webkit-box;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #262338;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 42px;
      }
      .title_mylib_home {
        display: -webkit-box;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #262338;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 45px;
      }
      .course_name {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #6e7191;
        margin-top: 2px;
        margin-bottom: 4px;

        span {
          color: #423DCF;
        }

        .course_name_hover {
          color: #423dcf;
        }
      }

      .author {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #4e4b66;
        margin-top: 2px;
        margin-bottom: 8px;
      }
      .calenderWrapper{
        display: flex;
        text-align: center;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: #a0a3bd;
        margin-top: 2px;
        margin-bottom: 8px;
        .date {
          margin-top: 2px;
          display: inline-block;
          margin-left: 4px;
        }
      }
      .dateWrapper {
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: #a0a3bd;
        .calenderIconImg {
          margin-top: 10px;
        }
        .date {
          display: inline-block;
          margin-left: 4px;
        }
      }
    }

    .actionsWrapper {
      display: flex;
      justify-content: center;
      border-top: 1px dashed rgba(0, 0, 0, 0.1);
      padding-top: 8px;
      margin-top: 14.21px;

      &:empty {
        display: none;
      }

      .joinNowBtn {
        display: block;
        font-size: 13px;
        font-weight: 900;
        line-height: 22px;
        letter-spacing: 0.25px;
        text-decoration: none;
        color: courseDashboardColor('700');

        &[disabled] {
          color: #a6a0a0;
          pointer-events: none;
        }
      }
      .Home_Lib_offset_font {
        cursor: pointer;
        font-size: 14px !important;
      }
      .Home_Lib_offset {
        position: absolute;
        left: 11%;
        font-size: 14px !important;
        @include respond(tablet) {
          position: absolute;
          left: 30%;
        }
        @include respond(mobile) {
          position: absolute;
          left: 17%;
        }
      }
      .Home_Lib_offset_view_recorded {
        position: absolute;
        cursor: pointer;
        left: 29%;
        font-size: 14px !important;
        @include respond(tablet) {
          position: absolute;
          left: 37%;
        }
        @include respond(mobile) {
          position: absolute;
          left: 30%;
        }
      }
      .recordedSession {
        cursor: pointer;
        display: inline-block;
        font-size: 13px;
        font-weight: 900;
        line-height: 22px;
        letter-spacing: 0.25px;
        color: #423dcf;
        text-decoration: none;
        cursor: pointer;
      }
      .Home_Lib_infoText_offset {
        height: 18px;
        margin-top: 4px;
      }
      .infoText {
        display: inline-block;
        margin-top: 4px;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #c9c9c9;
        .recorded_Session {
          color: #a6a0a0;
        }
      }
    }
  }

  .statusBadge {
    position: absolute;
    display: inline-flex;
    padding: 3px 6px;
    bottom: 15px;
    border-radius: 4px;

    .statusBadgeIcon {
      display: inline-block;
      margin-right: 5px;
    }

    .statusBadgeIconImg {
      display: inline-block;
      height: 12px;
      width: 12px;
      margin-right: 5px;
    }

    .statusBadgeText {
      display: inline-block;
      color: #fcfcfc;
      font-weight: 500;
      font-size: 8px;
      line-height: 11px;
    }

    &.classLive {
      background-color: #e61b00;
      color: #ffffff;
    }

    &.classEnded {
      background-color: #4e4b66;
      color: #ffffff;
    }

    &.classRescheduled {
      background-color: #946300;
      color: #ffffff;
    }

    &.classCancelled {
      background-color: #a6a0a0;
      color: #ffffff;
    }

    &:not(.classLive) {
      position: absolute;
      top: -10px;
      left: auto;
      right: -6px;
    }
  }

  &.haveNotificationEnabled {
    margin-bottom: 10px;
  }

  &.isCompact {
    flex-direction: row;
    margin-right: 12px;
    padding: 6px 10px 6px 10px;
    max-width: 345px;
    margin-left: 0;

    .thumbnailWrapper {
      flex: 0 0 110px;
      height: 110px;
      margin-right: 12px;
      background: linear-gradient(252.98deg, #6f5fbd 0%, #ab99ff 97.6%);
    }

    .statusBadge {
      position: absolute;
      top: -10px;
      bottom: auto;
      left: auto;
      right: -6px;
    }

    .contentWrapper {
      width: auto;
      padding-top: 8px;

      .infoWrapper {
        .title {
          // height: auto;
        }

        .course_name span {
          color: #6e7191;
        }

        .author {
          color: courseDashboardColor('300');
        }
      }

      .actionsWrapper {
        flex: 1;
        justify-content: flex-start;
        margin-top: 10px;
      }
    }
  }
}

.skeleton {
  display: flex;
  background: #ffffff;
  padding: 6px 10px 6px 10px;
  border: 1px solid #ebecfe;
  border-radius: 6px;
  max-width: 345px;

  @include respond(tablet) {
    max-width: 100%;
  }

  * {
    border-radius: 2.5px;
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .thumbnailWrapper {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    flex: 0 0 110px;
    background: #f6f6f6;
    border-radius: 6px;
    overflow: hidden;
    height: 110px;
    padding: 8px;
    background: linear-gradient(90deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
    background-size: 200%;
    animation-name: shimmer;

    .thumbnailIcon {
      position: absolute;
      bottom: 11px;
      right: 11px;
    }
  }

  .contentWrapper {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    align-self: flex-start;
    justify-content: space-between;
    padding-top: 8px;

    .infoWrapper {
      display: flex;
      flex-direction: column;

      .title {
        display: inline-block;
        width: 75%;
        height: 16px;
        background-color: #f6f6f6;
        border-radius: 4px;
        background: linear-gradient(90deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
        background-size: 200%;
        animation-name: shimmer;
      }

      .author {
        margin-top: 4px;
        margin-bottom: 8px;
        width: 30%;
        height: 16px;
        background-color: #f6f6f6;
        border-radius: 4px;
        background: linear-gradient(90deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
        background-size: 200%;
        animation-name: shimmer;
      }

      .dateWrapper {
        display: inline-block;
        width: 50%;
        height: 16px;
        background-color: #f6f6f6;
        border-radius: 4px;
        background: linear-gradient(90deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
        background-size: 200%;
        animation-name: shimmer;
      }
    }
  }

  @keyframes shimmer {
    0% {
      background-position: 200%;
    }
    100% {
      background-position: 0;
    }
  }
}

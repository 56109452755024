@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .container {
    .inputWrapperContainer {
      span {
        display: none;
      }
    }
    &.MyDoubtsContainer {
      height: 586px;

      @include respond(mobile) {
        // height: unset;
        min-height: 70vh;
      }
    }
  }

  &.MyDoubtsCourseDash {
    .header {
      display: none;
    }
    .container {
      .inputWrapperContainer {
        span {
          display: block;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        form {
          button {
            @media screen and (min-width: 767px) {
              display: none;
            }
          }
        }
      }
    }
  }

  .header {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    justify-content: space-between;
    padding-bottom: 10px;

    .closeIcon {
      cursor: pointer;
    }

    .title {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 12px;
    border: 1px solid #2c85e4;
    border-radius: 4px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;

    .chatContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      overflow: scroll;
      overflow-x: hidden;

      .chatRecieved,
      .chatSent {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 8px;
        border-radius: 4px;
        width: 75%;
        z-index: 0;
        margin-bottom: 8px;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          height: 0;
          width: 0;
          border: 5px solid transparent;
          z-index: -1;
        }

        .chatSender {
          color: rgba(0, 0, 0, 0.4);
        }

        .chatMessage {
          display: inline-block;
          color: #14142b;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          margin: 2.5px 0px;
        }

        .chatDateTime {
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
          text-align: right;
          color: #6e7191;
        }
      }

      .chatRecieved {
        background: #ebecfe;
        margin-right: auto;

        &::before {
          left: -7px;
          border-top-color: #ebecfe;
          border-right-color: #ebecfe;
        }

        & + .chatRecieved::before {
          display: none;
        }
      }

      .chatSent {
        background: #f7f7fc;
        margin-left: auto;

        &::before {
          right: -7px;
          border-top-color: #f7f7fc;
          border-left-color: #f7f7fc;
        }

        & + .chatSent::before {
          display: none;
        }
      }
    }

    .inputWrapperContainer {
      display: flex;

      span {
        border: none;
        width: unset;
      }
    }

    .inputWrapper {
      display: flex;
      padding: 12px;
      gap: 10px;
      border: 1px solid courseDashboardColor('700');
      border-radius: 6px;
      width: 100%;

      .attachmentIcon {
        display: inline-block;
        font-size: 20px;
        line-height: 0;
        color: courseDashboardColor('700');

        input {
          display: none;
        }
      }

      .chatInput {
        display: flex;
        flex: 1;
        font-size: 16px;
        line-height: 0;
        cursor: text;
      }

      .sendChatBtn {
        display: inline-block;
        font-size: 20px;
        line-height: 0;
        color: courseDashboardColor('700');
      }
    }

    .noRecordsWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      text-align: center;

      .noRecordsContent {
        .thumbImage {
          display: block;
          margin: 0 auto;
          margin-bottom: 16px;
        }

        .title {
          display: inline-block;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #14142b;
        }

        .subtitle {
          display: inline-block;
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #a0a3bd;
          margin-top: 8px;
        }
      }
    }

    .loadingWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      color: courseDashboardColor('300');
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.actionsWrapper {
  display: flex;
  gap: 0 8px;
  align-items: center;
  margin-left: auto;

  .facultyName {
    display: inline-block;
    max-width: 12ch;
    color: var(--alias-grayscale-placeholder, #a0a3bd);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .bookmarktextColor {
    color: var(--alias-primary-background-strong, #bfbefc);
    font-size: 12px;
    max-width: 106px;
  }

  .calendar {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    color: var(--alias-grayscale-placeholder, #a0a3bd);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    gap: 0 3px;

    .calendarIcon {
      display: inline-block;
    }
  }

  .actionButton {
    display: flex;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
    margin-left: auto;
    align-items: center;
    color: #423dcf;

    .RightArrow {
      margin-left: 5px;
      @media only screen and (max-width: 767px) {
        margin-left: 15px;
      }
    }

    &.type-video,
    &.type-test-series {
      color: courseDashboardColor('700');
      padding-right: 15px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 2px;
        transform: translateY(-50%) rotate(45deg);
        height: 8px;
        width: 8px;
        border: 2px solid courseDashboardColor('700');
        border-left-width: 0px;
        border-bottom-width: 0px;
      }
    }

    &.view-result {
      color: #0576f0;
      &::after {
        border-color: #0576f0;
      }
    }

    &.start-now {
      color: #008a00;
      &::after {
        border-color: #008a00;
      }
    }

    &.disabled {
      filter: grayscale(1);
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }
    &.reattemptButton{
      justify-content: center;
      font-family: Avenir;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      width: 78px;
      height: 22px;
      border-radius: 4px;
      margin-right:8px;
      border: 1px solid var(--alias-primary-primary-study-iq-colour, #423DCF);

      .tooltip .tooltiptext {
        visibility: hidden;
        padding: 6px 8px;
        width: 184px;
        background: rgba(0, 0, 0, 0.70);
        white-space: break-spaces;
        border-radius: 4px;
        text-align: start;
        font-family: 'Avenir';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        position: absolute;
        color: var(--alias-grayscale-background, #FCFCFC);
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -60px;
        }
        .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 30%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.70) transparent transparent transparent;
        }
      .tooltip:hover .tooltiptext {
        visibility: visible;
      }
    }
    &.reattemptButton::after{
      content: "";
      position: absolute;
      top: 0;
      right: -10px;
      height: 100%;
      border-right: 2px solid var(--alias-grayscale-line, #D9DBE9);
    }
  }
}
.textCourseTitle {
  color: var(--alias-grayscale-label, #6e7191);
  font-family: 'Avenir';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  .textCourseName {
    color: #423dcf;
  }
}

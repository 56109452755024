@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.infoBar {
  display: flex;
  // justify-content: space-between;
  color: rgba(0, 0, 0, 0.4);
  align-items: center;

  .infoItemsWrapper {
    display: flex;
    gap: 0 5px;
    align-items: center;

    .watchOuterBox {
      display: flex;
      .watchVideoText {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.4);
        padding-left: 6px;
      }
    }

    .isNewItem {
      display: inline-flex;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #eeeefb;
      color: #423dcf;
      font-size: 10px;
    }
    .isBookmarkNew {
      font-size: 10px;
    }
  }
}

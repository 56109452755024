$primary: #423DCF;
$black_1: #484848;
$dark_1: #393939;
$dark_2: #848999;
$dark_3: #090023;
$dark_4: #757575;
$dark_5: #1a1d21;
$dark_6: #343434;
$grey_1: #6c6c6c;
$grey_2: #0000001a;
$grey_3: #ebeded;
$grey_4: #f2f4f4;
$grey_5: #797979;
$orange_1: #423DCF;
$orange_2: #f37c36;
$orange_3: #fb9c46;
$green-shadow: #058e6e24;
$dark-green-shadow: #058e6e33;
$green_1: #058e6e;
$red_1: #dc3545;
$grey-shadow: #262d7614;
$grey_shadow_2: #0000001f;
$light_1: #f6f3ee;
$blue_1: #4d3c7c;
$black: #000;
$white: #fff;
$color_2: #333;
$background-color_1: #111;
$background-color_2: #555;

// Course Dashboard
$course-dashboard-colors: (
  '50': #eef3ff,
  '100': #dfe8ff,
  '200': #c6d3ff,
  '300': #a3b7fe,
  '400': #7f8ffa,
  '500': #6069f4,
  '600': #4543e8,
  '700': #423dcf,
  // Main Color
  '800': #302ea5,
  '900': #2c2d83,
  '950': #1b1a4c,
);

@use "sass:map";

@function courseDashboardColor($color) {
  @if map.has-key($course-dashboard-colors, $color) {
    @return map.get($map: $course-dashboard-colors, $key: $color);
  }

  @return map.get($map: $course-dashboard-colors, $key: '700');
}

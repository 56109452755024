@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
$black: #000;
$white: #fff;
$color_2: #333;
$background-color_1: #111;
$background-color_2: #555;
$color_3: #64748b;
$newFont: 'Avenir';

.fixmocksSliderWrappersformobile {
  display: none;
}

.leftBtn {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;
  transform: rotate(180deg);

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 11px;
    width: 2px;
    height: 8px;
    // transform: rotate(45deg);
  }

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 11px;
    width: 2px;
    height: 8px;
    transform: rotate(-45deg);
  }
}

.activeLeft {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 11px;
    width: 2px;
    height: 8px;
    transform: rotate(45deg);
    background-color: #ffffff;
  }

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 11px;
    width: 2px;
    height: 8px;
    transform: rotate(-45deg);
    background-color: #ffffff;
  }
}

.rightBtn {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 12px;
    width: 2px;
    height: 8px;
    transform: rotate(45deg);
    background-color: #dfdfdf;
  }

  &::after {
    content: '';
    position: absolute;
    top: 6px;
    left: 12px;
    width: 2px;
    height: 8px;
    transform: rotate(-45deg);
    background-color: #dfdfdf;
  }
}

.activeRight {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 11px;
    width: 2px;
    height: 8px;
    transform: rotate(45deg);
    background-color: #ffffff;
  }

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 11px;
    width: 2px;
    height: 8px;
    transform: rotate(-45deg);
    background-color: #ffffff;
  }
}
.fixmocksSliderWrappers {
  z-index: 0;
  position: relative;
  font-family: $newFont;

  .fixmocksSliders {
    padding: 1em 2px;

    .fixmockSlides {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .fixmockText {
      color: #000;
      font-family: $newFont;
      font-size: 16px;
      font-weight: 500;
      margin-top: 1rem;
    }
  }
}

.maxFeaturesSliderWrappers {
  z-index: 0;
  position: relative;
  padding: 0px 45px;
  margin-top: 2rem;

  .maxFeaturesSliders {
    padding: 0px 2px;

    .maxSwiperSlide {
      height: 220px !important;
      margin-top: 5px !important;
    }

    .maxFeatureSlides {
      display: flex;
      justify-content: center;
      padding: 4rem 0;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
      margin: 2rem;
      border-radius: 2rem;
      cursor: pointer;
    }

    .maxFeatureText {
      margin: 1rem;
      text-align: center;
      color: #000;
      font-family: $newFont;
      font-size: 16px;
      font-weight: 500;
    }

    :global(.swiper-pagination-bullet-active) {
      background-color: #fb9c46;
    }
  }
}

.fixmocksliderNavigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  z-index: 5;
  transform: translateY(-55%);

  @media only screen and (max-width: 640px) {
    display: none;
  }
  .swiperButtonNext {
    top: 50%;
    right: -1rem;
    position: absolute;
    width: 7rem;
    cursor: pointer;
    transform: rotate(180deg);
    img {
      transform: rotate(180deg);
    }
  }
  .swiperButtonPrev {
    top: 50%;
    left: -12.5rem;
    position: absolute;
    width: 8rem;
    cursor: pointer;
  }
}

.minMobileFeaturesSliderWrappers {
  z-index: 0;
  position: relative;
  // padding: 1em;
  margin-top: 2rem;

  .minMobileFeaturesSliders {
    padding: 1em;
    background: #ffffff;
    height: 200px;

    .minMobileSwiperSlide {
      height: 82px !important;
      margin-top: 5px !important;
    }

    .minMobileFeatureSlides {
      display: flex;
      justify-content: center;
      padding: 2rem 0;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
      margin: 2rem;
      border-radius: 2rem;
      cursor: pointer;
    }

    .minMobileFeatureText {
      color: #000;
      font-family: $newFont;
      font-size: 16px;
      font-weight: 500;
      margin-top: 1rem;
    }

    :global(.swiper-pagination-bullet-active) {
      background-color: #fb9c46;
    }
  }
}

.maxMobileFeaturesSliderWrappers {
  z-index: 0;
  position: relative;
  margin-top: 2rem;

  .maxMobileFeaturesSliders {
    padding: 0px 2px;
    background: #ffffff;
    height: 450px;

    .maxMobileSwiperSlide {
      height: 200px !important;
      margin-top: 5px !important;
    }

    .maxMobileFeatureSlides {
      display: flex;
      justify-content: center;
      padding: 2rem 0;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
      margin: 2rem;
      border-radius: 2rem;
      cursor: pointer;
    }

    .maxMobileFeatureText {
      color: #000;
      font-family: $newFont;
      font-size: 16px;
      font-weight: 500;
      margin-top: 1rem;
      text-align: center;
    }

    :global(.swiper-pagination-bullet-active) {
      background-color: #fb9c46;
    }
  }
}

.examRightArrow {
  margin-left: -49px;
}
.examLeftArrow {
  margin-left: 49px;
}
// Corner Ribbon Component
.box {
  width: 98% !important;
  position: relative;
  height: 160px;
  background: #fff;
  width: 100%;
  border-radius: 18px;
  border: 1px solid rgba(66, 61, 207, 0.20);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
  .bannerimage {
    display: flex;
    margin: 1em 0px;
    justify-content: space-around;
    .imagewrapper {
      border-right: 1.31px dashed rgba(0, 0, 0, 0.2);
      padding: 0 30px;
    }
    .btn_primary {
      display: block;
      font-weight: 700;
      text-align: center;
      background-color: #423DCF;
      color: #ffffff;
      border-radius: 6px;
      letter-spacing: 0.01em;
      width: 147px;
      font-size: 18px;
      line-height: 22px;
      font-weight: bolder;
      box-sizing: border-box;
      white-space: nowrap;
      cursor: pointer;
      padding: 14px 26px 14px 26px;
      font-family: $newFont;
    }
  }
  .clockSectionColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 50px;

    @include respond(tablet) {
      padding: 20px 36px;
    }

    @include respond(mobile) {
      padding: 20px 12px;
    }

    &:not(:last-child) {
      border-right: 1.31px dashed rgba(0, 0, 0, 0.2);
    }
  }
  .timewrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    letter-spacing: 0.2rem;
    // text-align: center;
    justify-content: center;
    .start {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #888785;
      line-height: 32px !important;
    }
    .time {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0em;
      white-space: nowrap;
      text-align: center;
      font-family: $newFont !important;
    }
  }

  .title {
    font-family: $newFont;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #172849;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 280px;
    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .cursetype {
    font-size: 16px;
    display: flex;
    justify-content: center;
    color: #423DCF;
    font-weight: bold;
    letter-spacing: 0;
  }

  .image {
    margin-right: 1em;
    height: 80px !important;
  }
}

.strpeText {
  font-size: 10px;
  line-height: 12px;
}

.prev_course_swipper {
  transform: rotate(348deg);
  position: absolute;
  max-width: fit-content;
  cursor: pointer;
}

.next_course_swipper {
  position: absolute;
  max-width: fit-content;

  @include respond(bigLaptop) {
  }
}

.textDecoration {
  text-decoration: none;
}

@include respond(tablet) {
  .fixmocksSliderWrappers {
    display: none;
  }
  .column1 {
    margin-left: 50px;
    @include respond(mobile) {
      margin-left: unset;
    }
  }

  .fixmocksSliderWrappersformobile {
    display: block;
    background: #ffffff;
    .minMobileFeaturesSliders {
      padding: 0px 50px;
      background: #ffffff;
      height: 125px;

      .minMobileSwiperSlide {
        height: 82px !important;
        margin-top: 5px !important;
      }

      .minMobileFeatureSlides {
        display: flex;
        justify-content: center;
        padding: 2rem 0;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
        margin: 2rem;
        border-radius: 2rem;
        cursor: pointer;
      }

      .minMobileFeatureText {
        color: #000;
        font-family: $newFont;
        font-size: 16px;
        font-weight: 500;
        margin-top: 1rem;
      }
      :global(.swiper-pagination-bullet) {
        height: 4px;
        width: 4px;
        background-color: #c4c4c4;
        opacity: 1;
      }

      :global(.swiper-pagination-bullet-active) {
        background-color: #423DCf;
        // width: 1.8rem;
        border-radius: 2rem;
      }

      @include respond(mobile) {
        padding: 0px 10px;
      }
    }
    .fixmocksSliders {
      .fixmockSlides {
        width: 100%;
        display: flex;
      }

      .fixmockText {
        color: #000;
        font-family: $newFont;
        font-size: 16px;
        font-weight: 500;
        margin-top: 1rem;
      }
    }
    .btn_primary {
      display: flex;
      text-align: center;
      height: 24px;
      align-items: center;
      background-color: #423DCF;
      color: #ffffff;
      border-radius: 6px;
      letter-spacing: 0.01em;
      width: 70px;
      padding: 8px;
      font-size: 10px;
      line-height: 18px;
      justify-content: center;
      font-weight: bolder;
      box-sizing: border-box;
      cursor: pointer;
      font-family: $newFont !important;
    }

    .fixmockcardboxmobile {
      background-image: url(../../../assets/images/fixed-mock/fixmockbackground.svg);
      height: 82px;
      width: 100%;
      background-size: cover;
      border-radius: 8px;
      box-shadow: 0px 0px 16px rgba(177, 188, 199, 0.3);

      .title {
        font-family: $newFont;
        font-size: 20px;
        letter-spacing: 0em;
        text-align: left;
        font-weight: 600;
        line-height: 18px;
        display: flex;
        align-items: center;
        margin: 1em 1em 0em 1em;
        text-transform: capitalize;
        width: 250px;
        white-space: nowrap;
        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        @include respond(mobile) {
          margin: 1em 1em 0em 1.5em;
          margin-right: 12px;
          font-size: 14px;
          width: 196px;
          display: flex;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          // flex-flow: column;
        }
      }

      .contentwarpper {
        height: 82px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .column1 {
          width: 65%;
        }
      }

      .start {
        margin: 0em 0em 0em 1.2em;
        font-family: $newFont;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
        color: #888785;
      }
      .time {
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin-left: 6px;
        font-family: $newFont;
        color: #515151;
      }
    }
  }
  .horizontal_divider {
    border-left: 2.31px dashed rgba(0, 0, 0, 0.2);
    margin-right: 1em;
    height: 82px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 17px;
    padding-right: 21px;
    padding-bottom: 8px;
    padding-top: 4px;
    justify-content: center;
  }
  .fixmockstudentbanner {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
  }
}
.knowmore {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  padding-top: 0;

  .contentWrapper {
    max-width: 180px !important;
    max-height: 180px !important;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2.5px);
    border-radius: 10px;
    color: #ffffff;
    padding: 24px 12px;

    button[class*='closeBtn'] {
      display: none;
    }

    .content {
      display: flex;
      flex-direction: column;

      .contentIcon {
        display: block;
        font-size: 64px;
        margin-bottom: 20px;
        color: #a6f787;
      }

      .contentMessage {
        font-family: 'Avenir';
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
      }
    }
  }
}

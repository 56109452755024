@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;

  &:not(.colored) .sectionHeader {
    padding-left: 5px;
  }

  .sectionHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-right: 12px;

    .sectionHeaderTitleWrapper {
      display: flex;
      gap: 16px;

      .sectionHeaderTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .sectionIcon {
          margin-bottom: 12px;
        }

        .sectionTitle {
          display: block;
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 27px;
          color: #2f2f2f;
        }

        .sectionSubtitle {
          display: inline-block;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #a6a0a0;
          margin-top: 8px;
        }
      }
    }
  }

  .sectionContent {
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding: 5px;
  }

  &.colored {
    padding-top: 16px;
    padding-bottom: 15px;
    overflow: hidden;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    .sectionHeader .sectionHeaderTitleWrapper .sectionHeaderTitle {
      .sectionSubtitle {
        color: #4e4b66;
        font-weight: 400;
      }
    }

    @include respond(tablet) {
      .sectionHeader {
        padding: 0 17px;

        .sectionIcon {
          transform-origin: left;
          transform: scale(0.5);
          height: 24px;
        }

        .sectionHeaderTitleWrapper {
          display: flex;
          flex-direction: column;

          .sectionTitle {
            color: #2f2f2f !important;
          }
        }
      }
    }

    .contentWrapper {
      width: 100%;
      max-width: 1086px;
      margin: 0 auto;
    }

    .sectionContent {
      padding: 0;
    }

    &.themeOrange {
      background: linear-gradient(180deg, #ffafaf 0%, #ffffff 100%);
    }

    &.themeGreen {
      background: linear-gradient(180deg, #a6f787 0%, #ffffff 100%);
    }

    &.themePurple {
      background: linear-gradient(180deg, #a996ff 0%, #ffffff 100%);

      .sectionTitle {
        color: #4700ab !important;
      }
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 25px;

  .sliderSlide {
    width: 100% !important;
    max-width: 340px;
  }

  .navigationWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;
    z-index: 10;

    @include respond(mobile) {
      display: none;
    }

    .navPrevSlide,
    .navNextSlide {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 35px;
      background-color: #ffffff;
      border: 1px solid #423dcf;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      font-size: 18px;
      text-align: center;
      pointer-events: all;
      color: #423dcf;
      cursor: pointer;

      &:global(.swiper-button-disabled) {
        opacity: 0;
        visibility: hidden;
      }
      @include respond(tablet) {
        display: none;
      }
    }

    .navPrevSlide {
      transform: translateX(calc(-100% - 10px));
    }

    .navNextSlide {
      transform: translateX(calc(100% + 10px));
    }
  }

  .sliderPagination {
    position: absolute;
    bottom: -18px !important;
    left: 0;
    display: inline-flex;
    justify-content: center;

    :global {
      .swiper-pagination-bullet {
        background-color: #d9d9d9;
        opacity: 1;
        transition: all 0.3s;

        &.swiper-pagination-bullet-active {
          background-color: #423dcf;
          box-shadow: 0 0 5px 1px rgba($color: #423dcf, $alpha: 0.7);
        }
      }
    }
  }
}

.testimonialPreviewWrapper {
  padding-top: 25px;

  @include respond(tablet) {
    display: flex;
    align-items: center;
  }

  .testimonialVideoWrapper {
    width: 80vw;
    padding: 0;

    @include respond(mobile) {
      width: 90vw;
    }

    :global(.shaka-video-container) {
      width: 100%;
    }

    :global(button[class*='closeBtn']) {
      position: absolute;
      top: 5px;
      right: -50px;
      font-size: 31px;
      color: #fff;
      line-height: 1;
      z-index: 100;
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: none;
  gap: 8px;
  border: 2px solid rgb(66, 61, 207, 0.25);
  border-radius: 5px;
  padding: 15px;

  @include respond(mobile) {
    display: flex;
  }

  .iconWrapper {
    width: min-content;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;

    .contentTitleWrapper {
      display: flex;
      flex-direction: column;
      padding-top: 15px;

      .contentTitle {
        display: inline-block;
        font-family: Avenir;
        font-size: 13px;
        font-weight: 900;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }

      .contentSubtitle {
        font-family: Avenir;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba($color: #000000, $alpha: 0.8);
      }
    }
  }

  .contactInfoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 5px;
    border-radius: 8px;
    padding: 5px 15px;
    font-family: Avenir;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #423dcf;
    margin-top: 10px;

    &.withBorder {
      justify-content: flex-start;
      border: 1px solid rgba(66, 61, 207, 0.3);
    }

    a {
      text-decoration: none;
    }

    .phoneIcon {
      margin-right: 5px;
    }

    .whatsappLink {
      display: flex;
      align-items: center;
      gap: 0px 5px;
      margin-left: 8px;

      .whatsappIcon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #25d366;
        color: white;
        font-size: 12px;
        padding: 0px;
        width: 18px;
        height: 18px;
        line-height: 0;
        border-radius: 3px;
        text-align: center;
        margin: 0;
      }
    }

    .vericalLine {
      display: none;
    }

    &.withBorder .vericalLine {
      display: block;
      height: 24px;
      width: 1px;
      background-color: #a996ff;
      margin-left: 5px;
      transform: scale(1.2);
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
$newFont: 'Avenir';
.container {
  //Tagline and Banner Image

  .dfpBannerweb {
    display: flex;
    justify-content: center;
    @include respond(tablet) {
      display: none !important;
    }
    @include respond(mobile) {
      display: none !important;
    }
  }

  .row1 {
    @include fwcontainer;
    @include flex(row, center, space-between);
    gap: 5rem;
    padding: 2rem 0 0 0;
    margin-bottom: 28px;

    .column1 {
      .textContainer {
        max-width: 34rem;
        margin-top: 6.4rem;

        .headerText {
          color: #252525;
          text-transform: uppercase;
          font-family: $newFont;
          font-weight: 700;
          font-size: 3.8rem;
          letter-spacing: 0.048rem;

          span {
            color: #423DCF;
          }
        }

        @include respond(mobile) {
          .headerText {
            text-align: center;

            span {
              text-align: center;
            }
          }
        }

        .helperText {
          margin-top: 1.6rem;
          font-family: $newFont;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 142%;
        }

        @include respond(mobile) {
          .helperText {
            display: none;
          }
        }

        @include respond(mobile) {
          margin-top: 0.5rem;
        }
      }

      .formGroup {
        border: 0.1rem solid #423DCF;
        border-radius: 0.6rem;
        width: max-content;
        height: 4rem;
        margin-top: 2rem;

        .countryCode {
          color: var(--alias-grayscale-header, #14142B);
          border-right: 0.1rem solid rgba(0, 0, 0, 0.20);
          padding: 1rem 1.6rem 1rem 1.5rem;
          font-size: small;
          font-family: $newFont;
        }

        input {
          min-width: 23rem;
          border: none;
          margin: 0.2rem 0.5rem;
          height: 90%;
          font-size: small;
          font-family: $newFont;
          cursor: text;
          color: var(--alias-grayscale-header, #14142B);

          &:focus {
            box-shadow: none;
            border: none;
          }
        }
        input::placeholder {
          color: var(--alias-grayscale-label, #6E7191);
          font-family: $newFont;
          font-size: 12.74px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .getLinkBtn {
          background: #423DCF;
          color: $white;
          width: 9.2rem;
          height: 100%;
          text-align: center;
          border-radius: 0 0.5rem 0.5rem 0;
          font-family: $newFont;
          font-size: 1.55rem;
        }

        @include respond(mobile) {
          display: none;
        }
      }

      .successText {
        font-family: $newFont;
        color: $green_1;
        font-size: $normal;
        padding-left: 1rem;
        margin-top: 0.5rem;

        @include respond(mobile) {
          display: none;
        }
      }

      .errorText {
        font-family: $newFont;
        color: $red_1;
        font-size: $normal;
        padding-left: 1rem;
        margin-top: 0.5rem;

        @include respond(mobile) {
          display: none;
        }
      }

      .btnWrapper {
        display: flex;
        flex-direction: row;
        margin-top: 1.7rem;
        gap: 12px;
      }

      @include respond(mobile) {
        .btnWrapper {
          text-align: center;
          justify-content: center;
          gap: 8px;
        }
      }

      .desktopHeaderImg {
        display: block;
      }

      .mobileHeaderImg {
        display: none;
      }

      @include respond(mobile) {
        .desktopHeaderImg {
          display: none;
        }

        .mobileHeaderImg {
          display: block;
        }
      }
    }

    @include respond(tablet) {
      @include flex(row, center, space-around);
      margin-bottom: 2.8rem;
    }
  }

  // Choose your exam

  .chooseYourExam {
    background: #f2f2f2;
    text-align: center;
    color: $black;
    font-weight: 500;
    font-family: $newFont;
    font-size: $medium;
    padding: 1rem 0;
    max-width: 144rem;
    margin: auto;
  }

  .examSlider {
    @include fwcontainer;
    display: block;

    @include respond(mobile) {
      display: none;
    }
  }

  .mobileExamSlider {
    display: none;

    @include respond(mobile) {
      display: block;
    }
  }

  // fix mock

  .fixmock {
    gap: 2rem;
    @include fwcontainer;
    max-width: 1120px !important;
  }

  @include respond(tablet) {
    .fixmock {
      display: block;
      gap: 2rem;
    }
  }
  //What we Offer

  .row2 {
    @include flex(row, center, space-between);
    gap: 2rem;
    padding: 2rem 0;
    @include fwcontainer;

    .column2 {
      @include respond(tablet) {
        display: none;
      }

      .whatWeOfferContainer {
        width: 25rem;

        .captionText {
          color: $orange_3;
          font-size: $normal;
          font-family: $newFont;
          font-weight: bold;
        }

        .offerText {
          color: $dark_1;
          font-size: $very-big;
          font-weight: 600;
          font-family: $newFont;
        }
      }

      .offersContainer {
        min-width: 37.4rem;
        margin-top: 2rem;

        .startText {
          font-family: $newFont;
          font-size: $normal;
          color: $grey_1;
        }

        .offerSlider {
          // outline: 1px solid;
          width: 61.6rem;

          .offerBlock {
            background: $white;
            box-shadow: 0 1rem 6rem $grey-shadow;
            border-radius: 1.6rem;
            margin: 2rem 0rem 0rem;
            padding: 1rem;

            .offerContent {
              text-align: center;
              margin-top: -3rem;

              .mainText {
                color: $dark_1;
                font-size: $normal;
                font-family: $newFont;
                font-weight: 500;
              }

              .tagText {
                color: $dark_2;
                font-size: $small;
                font-family: $newFont;
              }
            }
          }
        }

        .offerWrapper {
          text-align: center;
          @include flex(row, center, center);

          .offerBlock {
            background: $white;
            box-shadow: 0 1rem 6rem $grey-shadow;
            border-radius: 1.6rem;
            width: 26.6rem;
            height: 11rem;
            margin: 7rem 2rem 0;
            position: relative;
          }

          .offerContent {
            position: absolute;
            bottom: 3.5rem;
            left: 3rem;
            width: 20rem;
            height: 11rem;

            .mainText {
              color: $dark_1;
              font-size: $normal;
              font-family: $newFont;
              font-weight: 500;
            }

            .tagText {
              color: $dark_2;
              font-size: $small;
              font-family: $newFont;
            }
          }
        }
      }
    }
  }

  @include respond(mobile) {
    .row2 {
      display: none;
    }
  }

  // What will you get

  .row3 {
    @include fwcontainer;
    @include flex(row, center, space-between);
    // gap: 2rem;
    padding: 1rem 0;
    margin-top: 4rem;
    margin-bottom: 5rem;
    @include respond(tablet) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      margin-bottom: 2.8rem;
      margin-top: 4px;
      padding-top: unset;
    }

    .column3 {
      @include respond(tablet) {
        .bannerImage {
          display: none;
        }
      }

      .youWillGet {
        max-width: 45rem;
        color: var(--alias-grayscale-header-weak, #262338);
        .headText {
          font-size: $very-big;
          font-weight: 600;
          font-family: $newFont;

          span {
            color: #423DCF;
          }
        }

        @include respond(tablet) {
          .headText {
            font-size: $big;
          }
        }

        .bodyText {
          font-size: $normal-big;
          color: var(--alias-grayscale-body, #4E4B66);
          font-family: $newFont;
          margin-top: 1.8rem;
        }

        .featuresWrapper {
          @include flex(row, flex-start, flex-start);
          flex-wrap: nowrap;
          gap: 1rem;
          padding: 1.5rem;
          background: $white;
          box-shadow: 0rem -0.2rem 12.8rem $green-shadow;
          border-radius: 1.6rem;
          margin-top: 3rem;

          .checkbox {
            align-self: center;
            margin-top: 0.5rem;
          }

          .features {
            color: #000;
            font-size: $medium;
            font-weight: 600;
            font-family: $newFont;
          }
        }

        @include respond(tablet) {
          .featuresWrapper {
            box-shadow: 0px -0.2rem 2rem $dark-green-shadow;
          }
        }
      }
    }

    @include respond(tablet) {
      @include flex(row, center, center);
    }
  }

  &.getLinkOuterWrapper {
    background-color: #fbfbfb;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    height: 380px;
    margin-bottom: 64px;

    @include respond(tablet) {
      height: unset;
      margin-bottom: 28px;
      background-color: unset;
      box-shadow: unset;
      padding: 16px;
    }
  }

  .row4 {
    @include fwcontainer;
    @include flex(row, center, space-between);
    gap: 0.5rem;
    position: relative;
    @include respond(tablet) {
      padding: 16px 12px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 24px 64px 0px rgba(0, 0, 0, 0.06);
    }

    .bannerImageWrapper_getLink {
      margin-left: 1.8rem;
      width: 37%;
      @include respond(tablet) {
        display: none;
      }
    }

    .mwebImageWrapperGetLink {
      display: none;
      @include respond(tablet) {
        display: inline;
        margin-top: 15px;
        margin-bottom: 24px;
      }
    }

    .heading_mweb {
      display: none;

      span {
        color: #fb9c46;
      }

      @include respond(mobile) {
        display: block;
        font-family: $newFont;
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3rem;
        text-align: center;
        letter-spacing: 0.02em;
        color: #262338;
      }
    }

    .column4 {
      @include respond(mobile) {
        margin-top: 1rem;
        // margin: 1rem 0rem 0rem 5rem;
      }

      &.getLinkTextWrapper {
        margin-bottom: 70px;
        @include respond(tablet) {
          margin-bottom: unset;
          margin-top: unset;
        }
      }

      .downloadWrapper {
        width: 60rem;
        position: relative;

        .downloadHeadText {
          font-family: $newFont;
          font-weight: 600;
          color: #14142b;
          font-size: 32px;
          font-style: normal;
          line-height: 140%;

          span {
            color: #423DCF;
          }
        }

        @include respond(tablet) {
          .downloadHeadText {
            color: #262338;
            text-align: center;
            font-family: $newFont;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.48px;
          }
        }

        .downloadHelperText {
          width: 498px;
          font-weight: 400;
          color: var(--alias-grayscale-body, #4E4B66);
          font-family: $newFont;
          font-size: $normal-big;
        }

        @include respond(tablet) {
          .downloadHelperText {
            display: none;
          }
        }

        .platformSupportWrapper {
          display: flex;
          flex-direction: row;
          margin: 16px 0 23px;
          gap: 24px;
          position: relative;
          cursor: default;

          .platformInnerWrapper {
            display: flex;
            height: 142px;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 12px;
            border-radius: 8px;
            background: #fff;
            box-shadow: 0px 0px 6.28px 0px rgba(0, 0, 0, 0.07);
          }
          .platformCaption{
            color: #14142B;
            font-family: $newFont;
            font-size: 16px;
            margin-top: 4px;
            height: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
          }
          .platformDescription{
            color:#6E7191;
            font-family: $newFont;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
          }

          @include respond(tablet) {
            display: none;
          }
        }

        .playStoreButtons {
          display: flex;
          flex-direction: row;
          gap: 16px;
        }

        .getLinkBox {
          @include flex(row, center, space-around);
          flex-wrap: wrap;
          font-family: $newFont;
          margin-top: 3rem;

          @include respond(mobile) {
            margin-top: 0rem;
            margin-left: 0;
            margin-right: 0;
          }

          .googlePlayBtn_offset {
            @include respond(mobile) {
              margin-top: 6px !important;
              // width: 7rem !important;
            }
          }

          .inputBoxWrapper {
            @include flex(row, center, flex-start);
            border: 0.2rem solid $grey_2;
            width: 40rem;
            border-radius: 1rem;
            height: 5rem;

            .inputBox {
              border-right: 0.2rem solid $grey_2;
              padding: 1rem;
              font-family: $newFont;
              font-size: $normal;
              color: #423DCF;
            }

            input {
              min-width: 23rem;
              border: none;
              margin-left: 1rem;
              height: 100%;
              font-size: $normal;
              font-family: $newFont;

              &:focus {
                box-shadow: none;
                border: none;
              }
            }
          }

          @include respond(tablet) {
            .inputBoxWrapper {
              border: 0.2rem solid $primary;

              .inputBox {
                border-right: 0.2rem solid $primary;
              }
            }
          }

          .getLinkButton {
            background: $primary;
            color: $white;
            width: 15rem;
            padding: 1.2rem;
            text-align: center;
            border-radius: 1rem;
            font-family: $newFont;
            font-size: $medium;
            font-weight: 500;
            margin: 0.5rem 0rem;

            @include respond(mobile) {
              padding: 1rem 2rem;
              margin-left: 0;
              width: 8rem !important;
            }
          }

          @include respond(tablet) {
            .getLinkButton {
              width: 12rem;
            }
          }

          @include respond(tablet) {
            .getLinkButton {
              width: 12rem;
            }
          }

          .googlePlayBtn {
            display: none;
          }

          @include respond(tablet) {
            .googlePlayBtn {
              display: block;
            }
          }
        }

        .donwloadCaptionText {
          width: 42rem;
          font-family: $newFont;
          color: $dark_2;
          font-size: $normal;
          padding-left: 1rem;
        }

        .successText {
          font-family: $newFont;
          color: $green_1;
          font-size: $normal;
          padding-left: 1rem;
          margin-top: 0.5rem;
        }

        .errorText {
          font-family: $newFont;
          color: $red_1;
          font-size: $normal;
          padding-left: 1rem;
          margin-top: 0.5rem;
        }

        @include respond(tablet) {
          .donwloadCaptionText {
            display: none;
          }
        }
      }

      @include respond(tablet) {
        .downloadWrapper {
          width: 30rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .ellipseImage {
      position: absolute;
      right: 0;
      bottom: 1;
      opacity: 0.8;
      margin-bottom: 40px;
    }

    @include respond(tablet) {
      .ellipseImage {
        display: none;
      }
    }

    @include respond(tablet) {
      @include flex(column, center, space-around);
    }
  }

  .row6 {
    @include fwcontainer;
    font-family: $newFont;
    font-style: normal;
    margin: 4rem auto 0rem auto;
    padding: 5rem 0 5rem 0;

    @include respond(tablet) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    @include respond(mobile) {
      margin: 0rem 0rem;
    }

    .shimmer {
      color: grey;
      display: inline-block;
      -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 60%) right/300% 100%;
      background-repeat: no-repeat;
      animation: shimmer 2s infinite;
      // font-size: 50px;
      // max-width:200px;
    }

    @keyframes shimmer {
      100% {
        -webkit-mask-position: left;
      }
    }

    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 2rem;
      line-height: 1.5rem;

      .heading_span {
        color: black;

        @include respond(mobile) {
          color: #fb9c46;
        }
      }

      @include respond(mobile) {
        font-size: 1.6rem;
        display: flex;
        align-items: left;
        justify-content: left;
      }
    }

    .tabsSwipper_main {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      text-align: center;
      margin: 5rem 0rem 1rem 0rem;
      padding: 0rem 1rem 0rem 1rem;
      background: #f7f7f7;
      border-radius: 2rem;

      @include respond(mobile) {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        flex-wrap: nowrap;
      }

      // .shimmer {
      //   display: inline-block;
      //   color:grey;
      //   background: #acacac -webkit-gradient(linear, 100% 0, 0 0, from(#acacac), color-stop(0.5, #ffffff), to(#acacac));
      //   background-position: -50rem top; /*50px*/
      //   background-repeat: no-repeat;
      //   -webkit-background-clip: text;
      //   -webkit-text-fill-color: transparent;
      //   -webkit-animation-name: shimmer;
      //   -webkit-animation-duration: 2.2s;
      //   -webkit-animation-iteration-count: infinite;
      //   -webkit-background-size: 50rem 100%; /*50px*/
      //   font-size: 90px;
      // }

      // @-webkit-keyframes shimmer {
      //     0% {
      //         background-position: -50rem top; /*50px*/
      //     }
      //     70% {
      //         background-position: 12.5rem top; /*200px*/
      //     }
      //     100% {
      //         background-position: 12.5rem top; /*200px*/
      //     }
      // }
      .tabsItem {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 4.5rem;
        color: #757575;
        line-clamp: 1;
        cursor: pointer;

        @include respond(smallLaptop) {
          font-size: 1.55rem;
        }

        @include respond(mobile) {
          font-size: 1.2rem;
          line-height: 3.2rem;
        }

        @include respond(tablet) {
          font-size: 1.2rem;
          line-height: 3.2rem;
        }
      }

      .tabsItemActive {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 4.5rem;
        padding: 2px 18px;
        color: #423DCF;
        line-clamp: 1;
        cursor: pointer;
        background: white;
        border-radius: 50px;
        box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);

        @include respond(smallLaptop) {
          font-size: 1.55rem;
        }

        @include respond(mobile) {
          font-size: 1.2rem;
          line-height: 3.2rem;
        }

        @include respond(tablet) {
          font-size: 1.2rem;
          line-height: 3.2rem;
        }
      }

      .next_swipper {
        cursor: pointer;
        width: 19rem;

        @include respond(mobile) {
          width: 13rem;
        }

        @include respond(tablet) {
          width: 14rem;
        }
      }

      .prev_swipper {
        cursor: pointer;
        width: 19rem;
        transform: rotate(180deg);

        @include respond(mobile) {
          width: 13rem;
        }

        @include respond(tablet) {
          width: 14rem;
        }
      }

      .next_swipper_Dis {
        cursor: pointer;
        width: 22rem;
        transform: rotate(180deg);

        @include respond(mobile) {
          width: 13rem;
        }

        @include respond(tablet) {
          width: 14rem;
        }
      }

      .image_wrapper_prev_swipper_Dis {
        cursor: pointer;
        width: 4.5rem;
        height: 2rem;
        margin-top: 0.2rem;

        @include respond(mobile) {
          margin-top: 0.8rem;
          width: 2.3rem;
        }

        @include respond(tablet) {
          margin-top: 0.8rem;
          width: 2.4rem;
        }
      }

      .image_wrapper_prev_swipper_Enable {
        cursor: pointer;
        width: 4.5rem;
        height: 2rem;
        margin-top: 0.2rem;
        transform: rotate(180deg);
        @include respond(mobile) {
          margin-top: 0.8rem;
          width: 2.3rem;
        }
        @include respond(tablet) {
          margin-top: 0.8rem;
          width: 2.4rem;
        }
      }

      .image_wrapper_next_swipper_Enable {
        cursor: pointer;
        width: 4.5rem;
        height: 2rem;
        margin-top: 0.2rem;
        // transform: rotate(180deg);
        @include respond(mobile) {
          margin-top: 0.8rem;
          width: 2.3rem;
        }
        @include respond(tablet) {
          margin-top: 0.8rem;
          width: 2.4rem;
        }
      }

      .image_wrapper_next_swipper_Dis {
        cursor: pointer;
        width: 4.5rem;
        height: 2rem;
        transform: rotate(180deg);
        margin-top: 0.15rem;

        @include respond(mobile) {
          margin-top: 0.05rem;
          width: 2.3rem;
        }

        @include respond(tablet) {
          margin-top: -0.75rem;
          width: 2.4rem;
        }
      }
    }

    .courseSwipper_main {
      //web
      position: relative;
      margin-left: 8px;

      .prev_course_swipper {
        transform: rotate(180deg);
        position: absolute;
        top: 38%;
        left: -7.5rem;
        @include respond(tablet) {
          left: 0;
        }

        cursor: pointer;
      }

      .next_course_swipper {
        position: absolute;
        top: 38%;
        right: -6rem;
        @include respond(tablet) {
          right: 0;
        }

        cursor: pointer;
      }

      .swiperSlideEach {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .products_card {
          margin: 1rem 0rem;
          cursor: pointer;

          img {
            width: 22rem;
            height: 14.74rem;
            border-radius: 0.6rem;
            margin-bottom: 0.7rem;
          }

          p {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.8rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      .swiperSlideEachGridSmall {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;

        @include respond(tablet) {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr 1fr;
        }
        align-self: center;
        justify-self: center;

        .products_card {
          margin: 1rem 0.5rem;
          cursor: pointer;

          img {
            width: 22rem;
            height: 14.74rem;
            border-radius: 0.6rem;
            margin-bottom: 0.7rem;
          }

          p {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.8rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      .swiperSlideEachGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        @include respond(tablet) {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr 1fr;
        }

        align-self: center;
        justify-self: center;

        .products_card {
          margin: 1rem 0.5rem;
          cursor: pointer;

          img {
            width: 22rem;
            height: 14.74rem;
            border-radius: 0.6rem;
            margin-bottom: 0.7rem;
          }

          p {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.8rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }

    .courseSwipper_main_mweb {
      //mweb
      .products_card_mweb {
        margin: 1rem 0.5rem;

        img {
          border-radius: 0.6rem;
          margin-bottom: 0.7rem;
        }

        p {
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.8rem;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }

  .row5 {
    @include flex(row, center, flex-start);
    position: relative;
    margin-top: 5rem;
    max-width: 1440px;
    margin: auto;

    .column5 {
      width: 40%;
      background: $grey_3;
      padding: 5.5%;
      height: 42.4rem;

      a {
        text-decoration: none;
      }

      .productsHeadText {
        text-transform: uppercase;
        font-family: $newFont;
        font-weight: 600;
        font-size: $medium-big;
      }

      .productsHelperText {
        color: $dark_1;
        font-family: $newFont;
        font-size: $normal;
      }

      .coursesBtn {
        @include flex(row, center, space-around);
        gap: 2rem;
        background: $white;
        opacity: 0.8;
        width: 19.4rem;
        padding: 1.5rem;
        border-radius: 3.2rem;
        margin-top: 2.5rem;
        text-decoration: none;

        .btnText {
          color: $primary;
          font-family: $newFont;
          font-weight: 500;
          font-size: $normal-big;
          margin-bottom: 0.2rem;
          width: 19.4rem;

          a:-webkit-any-link {
            color: $primary;
            font-family: $newFont;
            font-size: $normal;
            font-weight: 500;
            text-decoration: none;
          }

          svg {
            margin-left: 5.2rem;
          }
        }
      }
    }

    .column6 {
      width: 60%;
      background: $grey_4;
      display: grid;
      grid-template-columns: repeat(2, 28rem);
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 3rem;
      grid-row-gap: 4rem;
      justify-content: center;
      padding: 5rem 0 4rem;
      height: 42.4rem;

      .productsBlock {
        background: $white;
        box-shadow: 0 1rem 6rem $grey-shadow;
        border-radius: 1.6rem;
        width: 28rem;
        height: 13rem;
        position: relative;

        // img{
        //   position: absolute;
        //   left: 0rem;
        //   top:0rem;
        // }
        .productsContent {
          position: absolute;
          bottom: 5rem;
          left: 11rem;
          width: 20rem;
          height: 11rem;

          .productsText {
            margin-left: -8rem;
            color: $dark_1;
            font-size: $normal;
            font-family: $newFont;
            font-weight: 500;
          }

          .productsTagText {
            margin-left: -8rem;
            width: 23rem;
            color: $grey_1;
            font-size: $small;
            font-family: $newFont;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          a {
            text-decoration: none;
          }

          .productsLink {
            @include flex(row, center, flex-start);
            gap: 1rem;
            width: max-content;
            margin-left: -8rem;
            margin-top: 1rem;
            cursor: pointer;
            transition: transform 0.2s;

            .linkText {
              font-family: $newFont;
              color: $primary;
              font-weight: 500;
              font-size: $normal;
              margin-top: -0.5rem;

              a:-webkit-any-link {
                color: $primary;
                font-family: $newFont;
                font-size: $normal;
                font-weight: 500;
                text-decoration: none;
              }
            }
          }

          .productsLink:hover {
            transform: scale(1.05);

            .linkText {
              font-weight: 600;
            }
          }
        }
      }
    }

    .productsBgImg {
      position: absolute;
      left: 0;
    }

    @include respond(mobile) {
      display: none;
    }

    @include respond(tablet) {
      display: none;
    }
  }

  .productsContainer {
    display: none;

    @include respond(tablet) {
      display: block;
      overflow-x: scroll;

      .productsMobileText {
        text-transform: uppercase;
        font-weight: 600;
        font-family: $newFont;
        font-size: $big;
        margin: 2rem 1rem;
      }

      .productsWrapper {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: max-content;
        margin: 1rem 0;

        .productsBlock {
          background: $white;
          box-shadow: 0 1rem 6rem $grey-shadow;
          border-radius: 1.6rem;
          width: 23rem;
          height: 15rem;
          margin: 2rem 1rem;
          position: relative;

          @include respond(mobile) {
            width: 23rem;
            height: 13rem;
            margin: 2rem 1rem;
            position: relative;
            // img{
            //   position: absolute;
            //   left: 2rem;
            //   top:-5rem;
            // }
          }

          .productsContent {
            position: absolute;
            bottom: 7rem;
            left: 7rem;
            width: 14rem;
            height: 11rem;

            @include respond(mobile) {
              bottom: 4rem;
              left: 8.5rem;
              width: 14rem;
              height: 12rem;
            }

            .productsText {
              margin-left: -5rem;
              color: $dark_1;
              font-size: $normal;
              font-family: $newFont;
              font-weight: 500;
            }

            .productsTagText {
              margin-left: -5rem;
              width: 21rem;
              color: $dark_2;
              font-size: $small;
              font-family: $newFont;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .productsLink {
              @include flex(row, center, flex-start);
              gap: 1rem;
              width: max-content;
              margin-left: -5rem;
              margin-top: 1rem;

              .linkText {
                font-family: $newFont;
                color: $primary;
                font-weight: 500;
                font-size: $normal;
              }
            }
          }
        }
      }
    }
  }

  .row8 {
    @include fwcontainer;
    font-family: $newFont;
    display: grid;
    margin: 4rem auto 10rem auto;
    @include respond(mobile) {
      margin: 4rem auto 20rem auto;
    }
    @include respond(tablet) {
      margin: 4rem auto 25rem auto;
    }
    position: relative;

    .title_main {
      background: #f2f4f4;
      padding: 2rem 0 1rem 0;
      height: 16.4rem;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
      border-radius: 12px;
      @include respond(mobile) {
        padding: 1rem 1rem 2rem 1rem;
      }

      .p1 {
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3rem;
        text-align: center;
        color: #343434;
        @include respond(mobile) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .p2 {
        color: #946300;
        text-align: center;
        font-family: "Avenir";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        padding-top: 0.5rem;
        margin: auto;
        @include respond(mobile) {
          font-weight: 500;
          width: 80%;
          font-size: 12px;

        }
      }

      .middleSectionHeading {
        color: var(--alias-grayscale-header, #14142B);
      }
    }

    .cards_main {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 1rem;
      align-items: center;
      width: 87%;
      position: absolute;
      bottom: -50%;
      left: 8%;
      @include respond(mobile) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        bottom: -23rem;
        left: 5% !important;
      }
      @include respond(tablet) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        bottom: -23rem;
        left: 23%;
        width: 50%;
      }

      .card {
        // position: absolute;
        width: 38.8rem;
        height: 13.6rem;
        background: #ffffff;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr;
        @include respond(mobile) {
          margin-bottom: 1rem;
          width: 32.8rem;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;
        }
        @include respond(tablet) {
          margin-bottom: 1rem;
        }

        .img_wrapper {
          background-color: #cae9ff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 1.6rem;
          border-radius: 4px;
        }

        .img_wrapper_item2 {
          background-color: #efeaff;
        }

        .right_card_wrapper {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          width: 22.8rem;
          @include respond(mobile) {
            width: 18.8rem;
          }

          .p1 {
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2rem;
            color: #262338;
          }

          .p2 {
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 150%;
            margin-top: 5px;
            /* or 21px */
            display: flex;
            align-items: center;
            color: #6E7191;
          }

          .p3 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            width: fit-content;
            /* identical to box height */

            display: flex;
            align-items: center;
            cursor: pointer;
            color: #423DCF;
            margin-top: 1rem;

            .img_wrapper_explore {
              margin-left: 0.8rem;
            }

            // p:hover{
            //   margin-right: 0.1rem;
            // }
          }

          .p3:hover {
            font-weight: 600;
          }
        }

        // background-color: #343434;
      }
    }
  }

  .row7 {
    @include fwcontainer;
    background: white;
    font-family: $newFont;
    display: grid;
    margin: 4.8rem auto;
    padding: 1.2rem 0 1.2rem 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    // margin: 64px 4.8rem;
    color: var(--alias-grayscale-header, #14142B);

    @include respond(tablet) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;

      .testimonialsData {
        display: none;
      }
    }

    @include respond(mobile) {
      grid-template-columns: 1fr;
      grid-template-rows: 2fr;
      grid-row-gap: 1rem;
      align-items: flex-start;
      justify-items: center;
      margin: 2.8rem 0;
      padding: 0;
    }

    .left {
      position: relative;

      .heading {
        font-family: $newFont;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.2em;
        position: relative;
        display: flex;
        flex-direction: row;
        margin-bottom: 8rem;

        @include respond(mobile) {
          font-size: 1.6rem;
          text-align: left;
          margin-bottom: 0.5rem;
          margin-left: -15rem;
        }

        img {
          width: 5rem;
          height: 0.09rem;
          margin-right: 2rem;
          margin-top: 1.3rem;

          @include respond(mobile) {
            display: none;
          }
        }
      }

      .heading::before {
        content: '';
        width: 18rem;
        height: 15rem;
        position: absolute;
        top: 14rem;
        left: 16.5rem;
        background-image: url(../../public/Quote_Icon_P.png);
        background-repeat: no-repeat;

        @include respond(mobile) {
          width: 10rem;
          top: -1rem;
          left: 150%;
          background-size: 8rem 6.5rem;
        }
      }

      .p1 {
        font-family: $newFont;
        font-style: normal;
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 3.8rem;
        color: #262338;
      }

      .p3 {
        font-family: $newFont;
        font-style: normal;
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 3.8rem;
        color: #423DCF;
        margin-bottom: 1rem;
      }

      .p4 {
        color: var(--alias-grayscale-label, #6E7191);
        font-size: 1.6rem;
        margin-top: 2rem;
      }

      .p2 {
        color: var(--alias-grayscale-label, #6E7191);
        font-size: 1.6rem;

        .study {
          color: var(--alias-grayscale-header, #14142B);

          .iq {
            color: #423DCF;
          }
        }
      }
    }

    .right {
      width: 45rem;
      margin-left: auto;

      @include respond(tablet) {
        margin: 0 auto;
      }

      @include respond(mobile) {
        width: 26rem;
        margin-left: 12%;
      }

      .profilepicMain {
        position: relative;

        .prevIcon {
          position: absolute;
          transform: rotate(180deg);
          width: 6rem;
          bottom: 0.7rem;
          left: -5rem;
          cursor: pointer;
        }

        .nextIcon {
          position: absolute;
          cursor: pointer;
          right: -5rem;
          bottom: 0.7rem;
          width: 6rem;
        }

        .swiperSlideEach {
          margin: 1rem;
          padding: 1rem;

          .image_placeholder {
            background-color: rgb(245, 245, 245);
            display: flex;
            background-color: rgb(245, 245, 245);
            border: 0.3rem solid #F2F0FF;
            border-radius: 50%;
            height: 38.6rem;
            width: 38.6rem;
            box-shadow: 0 0 0 3.5rem #ffffff inset;
            box-sizing: border-box;

            @include respond(mobile) {
              border: 0.5rem solid #ff922f80;
              box-shadow: 0 0 0 2.4rem #ffffff inset;
              padding: 2.1rem;
              width: 23rem;
              height: 23rem;
            }

            h4 {
              align-self: center;
              text-align: center;
              width: 100%;
              color: rgb(170, 170, 170);
              font-size: 4rem;

              @include respond(mobile) {
                font-size: 2.5rem;
              }
            }
          }
        }

        @include respond(mobile) {
          width: 27rem;
          margin-left: 0;
          margin-top: 1rem;
        }

        .profilePic {
          border-radius: 50%;
          border: 0.3rem solid #F2F0FF;
          padding: 3.5rem;
          width: 38.6rem;
          height: 38.6rem;
          z-index: 9;

          @include respond(mobile) {
            border: 0.5rem solid #F2F0FF;
            padding: 2.1rem;
            width: 24rem;
            height: 24rem;
          }
        }

        .info {
          position: absolute;
          width: 28rem;
          height: 10rem;
          padding: 1rem 0.8rem;
          border: 1px solid #A0A3BD;
          border-left: 10px solid #393939;
          left: 6rem;
          bottom: 2.5rem;
          background: #FFF;
          border-radius: 16px;
          &:hover{
            border: 1px solid #423DCF;
            border-left: 10px solid #423DCF;
          }

          @include respond(mobile) {
            left: 2rem;
            bottom: 0rem;
            width: 23rem;
            height: 10rem;
            box-shadow: 2px 4px 12px rgba(41, 44, 124, 0.06);
          }

          background: #ffffff;
          border-radius: 5px;
          z-index: 1000;

          .infoLine {
            height: 8rem;
            width: 1px;
            position: absolute;
            background-color: #6E7191;
            @include respond(mobile) {
              height: 8.5rem;
            }
          }
          .play {
            position: absolute;
            right: 0.5rem;
            top: 0.4rem;
            width: 2rem;
            cursor: pointer;
          }

          .p1 {
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 600;
            color: #14142B;
          }

          .p2 {
            font-size: 1.2rem;
            font-weight: 400;
            color: #6E7191;
          }

          .rating {
            margin-top: 0.2rem;
            display: flex;
            flex-direction: row;

            img {
              width: 1.1rem;
            }
          }
          .infoMargin{
            margin-left: 6px;
          }
        }

        .icon1 {
          position: absolute;
          top: 5.5rem;
          left: 5.4rem;
          display: flex;
          align-items: center;

          @include respond(mobile) {
            top: 4rem;
            left: 3.2rem;
            padding: 0.8rem;
            transform: scale(0.6333, 0.6333);
          }

          z-index: 100;
          background: white;
          padding: 1rem;
          border-radius: 50%;
          box-sizing: border-box;
          background: #f6f6f6;
          border: 3px solid #ffffff;
          box-shadow: 0px -7px 89px rgb(233 233 233 / 17%), 0px -2.55512px 32.4865px rgb(198 194 194 / 12%),
            0px -1.24046px 15.7716px rgb(0 0 0 / 9%), 0px -0.608097px 7.73152px rgb(0 0 0 / 8%),
            0px -0.240442px 3.05705px rgb(0 0 0 / 5%);

          img {
            @include respond(mobile) {
              width: 1.9rem;
            }
          }
        }

        .icon2 {
          position: absolute;
          top: 12rem;
          right: 3.3rem;
          display: flex;
          align-items: center;

          @include respond(mobile) {
            top: 10rem;
            right: 0.4rem;
            padding: 0.8rem 0.9rem;
            transform: scale(0.6333, 0.6333);
          }

          z-index: 100;
          background: white;
          padding: 1.1rem 1.3rem;
          border-radius: 50%;
          box-sizing: border-box;
          background: #f6f6f6;
          border: 3px solid #ffffff;
          box-shadow: 0px -7px 89px rgb(233 233 233 / 17%), 0px -2.55512px 32.4865px rgb(198 194 194 / 12%),
            0px -1.24046px 15.7716px rgb(0 0 0 / 9%), 0px -0.608097px 7.73152px rgb(0 0 0 / 8%),
            0px -0.240442px 3.05705px rgb(0 0 0 / 5%);

          img {
            @include respond(mobile) {
              width: 1.7rem;
            }
          }
        }

        @include respond(mobile) {
          .icon1,
          .icon2 {
            box-shadow: 0px -7px 89px rgb(233 233 233 / 9%), 0px -2.55512px 32.4865px rgb(198 194 194 / 8%),
              0px -1.24046px 15.7716px rgb(0 0 0 / 4%), 0px -0.608097px 7.73152px rgb(0 0 0 / 4%),
              0px -0.240442px 3.05705px rgb(0 0 0 / 2%);
          }
        }
      }

      .h_t_v_wrapper {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 999999;
        display: flex;
        align-items: center;
        justify-content: center;

        .h_t_v_player {
          height: 350px;
          width: 600px;
          display: block;
          z-index: inherit;

          @media screen and (max-width: 600px) {
            height: 215px;
            width: calc(100vw - 4px);
          }

          .react-player__preview {
            background-size: contain !important;
            background-repeat: no-repeat !important;
          }
        }
      }
    }
  }

  .row10 {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    @include fwcontainer;
    @include flex(row, center, space-evenly);
    gap: 2rem;
    padding: 2.4rem;
    background: #f7f8fc;
    position: relative;
    margin-top: 15.6rem !important;
    // margin-bottom: 4.8rem;

    .bgImg {
      position: absolute;
      right: -1rem;
      z-index: 0;
    }

    .column10 {
      .cards {
        width: 23.4rem;
      }

      .infoText {
        width: 39.8rem;
        padding: 1.2rem;

        .exploreHeadText {
          font-family: $newFont;
          font-weight: 600;
          font-size: $big;
          color: var(--alias-grayscale-header-weak, #262338);
        }

        .exploreCaptionText {
          font-family: $newFont;
          font-weight: 400;
          font-size: $normal-big;
          color: var(--alias-grayscale-label, #6E7191);
        }
      }
    }

    @include respond(tablet) {
      display: none;
    }
  }

  .mobileContainer {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    display: none;

    @include respond(tablet) {
      display: block;

      .mobileRow8 {
        @include fwcontainer;
        padding: 1.2rem 2.4rem;
        background: #f7f8fc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2.4rem 0;

        .mobileExploreHeadText {
          font-family: $newFont;
          font-weight: 600;
          font-size: $normal-big;
          color: #262338;

        }

        .mobileExploreCaptionText {
          font-family: $newFont;
          font-weight: 500;
          font-size: $small;
          color: #4E4B66;
        }
      }

      .mobileRow9 {
        @include fwcontainer;
        // @include flex(row, center, space-evenly);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        overflow: scroll;
        gap: 2rem;
        margin: 2.8rem 0;
        padding: 1.2rem 2.4rem;
        background: #f7f8fc;
        padding-bottom: 4px;
        // For IE and MS Edge
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .mobileColumn8 {
          width: 23.8rem;
          // height: 19.6rem;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    @include respond(mobile) {
      display: block;

      .mobileRow8 {
        @include fwcontainer;
        padding: 1.2rem 1.6rem;
        background: #f7f8fc;
        display: flex;
        align-items: flex-start;
        padding-bottom: 4px;

        .mobileExploreHeadText {
          font-family: $newFont;
          font-weight: 600;
          font-size: $normal-big;
          color: #262338;
        }

        .mobileExploreCaptionText {
          font-family: $newFont;
          font-weight: 500;
          font-size: $small;
          color: #4E4B66;
          margin-top: 0.2rem;
        }
      }

      .mobileRow9 {
        @include fwcontainer;
        // @include flex(row, center, space-evenly);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        overflow: scroll;
        gap: 2rem;
        padding: 1.2rem 1.6rem;
        background: #f7f8fc;
        // For IE and MS Edge
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .mobileColumn8 {
          width: 23.8rem;
          // height: 19.6rem;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
      .mobileRow9:first-child {
        padding-bottom: 4px;
      }
    }
  }

  .row9 {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    @include fwcontainer;
    @include flex(row, center, space-evenly);
    margin-top: 32px !important;
    gap: 2rem;
    padding: 2.4rem;
    background: #f7f8fc;
    position: relative;

    .bgLeftImg {
      position: absolute;
      left: -2rem;
      z-index: 0;
    }

    .column9 {
      .cards {
        width: 23.4rem;
      }

      .infoText {
        width: 39.8rem;
        padding: 1.2rem;

        .exploreHeadText {
          font-family: $newFont;
          font-weight: 600;
          font-size: $big;
          color: var(--alias-grayscale-header-weak, #262338);
        }

        .exploreCaptionText {
          font-family: $newFont;
          font-weight: 400;
          font-size: $normal-big;
          color: var(--alias-grayscale-label, #6E7191);
        }
      }
    }

    @include respond(tablet) {
      display: none;
    }
  }
}

.dfpBannermweb {
  display: none;
  @include respond(mobile) {
    min-width: 320px;
    display: flex;
    justify-content: center;
  }
  @include respond(tablet) {
    min-width: 320px;
    display: flex;
    justify-content: center;
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.resourceCourseWrapper {
  @media screen and (max-width: 767px) {
    padding: 0px !important;
  }
}

.languageModalOuter {
  position: fixed;
  top: 0px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.languageModal {
  position: fixed;
  background: #ffffff;
  // padding: 24px 0px 48px 40px;
  bottom: 0;
  width: 100%;
  z-index: 1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .rightIcon {
    padding-right: 46px;
    font-size: 21px;
    cursor: pointer;
  }

  .unselectedLanguage {
    border: 1px solid rgba(66, 61, 207, 0.42);
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
  }

  .activeLanguageContent {
    border: 1.5px solid #423dcf;
  }

  .modalHeadingBlock {
    display: flex;
    justify-content: space-between;
    padding: 24px 10px 16px 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    align-items: center;
  }

  .modalHeading {
    color: #423dcf;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }

  .modalContentHeading {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(9, 0, 35, 0.8);
    padding: 24px 50px 20px 40px;
  }

  .languageContent {
    border: 1.5px solid #423dcf;
    border-radius: 13px;
    padding: 13px 0px 13px 13px;
    display: flex;
    align-items: center;
    -moz-column-gap: 20px;
    column-gap: 20px;
    cursor: pointer;
    min-width: 220px;
    .leftIconBlock {
      display: flex;
      align-items: center;
    }
  }

  .languageContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    gap: 10px;
    padding: 24px 0px 20px 40px;
    align-items: flex-start;
  }

  .languageName {
    color: #423dcf;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1086px;
  width: 100%;
  margin: 0 auto;
  padding: 0px;
  font-family: $font-family;
  position: relative;

  .headingSectionContainer {
    display: flex;
    justify-content: space-between;
    @media screen and (min-width: 767px) {
      display: none;
    }

    .rightIconBlock {
      float: right;
      cursor: pointer;
    }

    .headingContainer {
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.035em;
      color: #14142b;
      background: #f8f7fc;
      padding: 6px 16px;
      margin-bottom: 16px;

      .filterStyle {
        padding: 10px;

        &:hover {
          cursor: pointer;
        }
      }

      @include respond(mobile) {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 25px 0px;
  }

  .courseDashboardTopBar {
    display: flex;
    margin-bottom: 15px;
    gap: 0px 30px;

    .filterStyle {
      padding: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    @media only screen and (max-width: 767px) {
      // flex: 1;
      display: none;
    }

    .filterWrapper {
      position: absolute; 
      right: 10px; 
      padding: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    .courseSearchInputWrapper {
      display: flex;
      flex: 0 0 286px;
      align-items: center;
      background: #ffffff;
      border-radius: 6px;
      padding: 11px 15px;
      font-size: 18px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      cursor: text;

      .searchIcon {
        width: 30px;
      }
      .clearSearchIcon {
        width: 20px;
        cursor: pointer;
      }
      @media only screen and (max-width: 767px) {
        // flex: 1;
        display: none;
      }

      .courseSearchInput {
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
      }
    }

    .courseBreadcrumbWrapper {
      display: flex;
      width: 100%;
      @media only screen and (max-width: 767px) {
        display: none;
      }

      .courseBreadcrumbBackBtn {
        display: block;
        width: 42px;
        height: 42px;
        margin-right: 14px;
        padding: 12px;
        color: courseDashboardColor('700');
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        font-size: 15px;
        line-height: 1;
        box-sizing: border-box;
        text-align: center;
      }

      .courseBreadcrumb {
        display: flex;
        width: 100%;
        background: #ffffff;
        border-radius: 7px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        padding: 11.5px 19px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;

        b {
          display: inline-block;
          color: courseDashboardColor('700');
          margin-right: 12px;
        }
      }
    }
  }

  .mWebShowSidebar {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
  .mWebShowRightBlock {
    display: none !important;
  }
  .resourseCourseContentWrapper {
    @media only screen and (max-width: 767px) {
      margin: 0px !important;
    }
  }
  .courseContentWrapper {
    display: flex;
    gap: 0px 20px;

    @media only screen and (max-width: 767px) {
      margin: 0px 16px;
    }

    .courseDashboardSidebar {
      display: flex;
      flex-direction: column;
      width: 300px;
      gap: 12px 0px;
      height: 586px;
      overflow: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        display: none;
      }

      @media only screen and (max-width: 767px) {
        height: unset;
        overflow: unset;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        padding: 12px;
      }
    }

    .courseItemsOutterWrapper {
      flex: 1;
      // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      // border-radius: 6px;
      // overflow: hidden;
      // padding: 24px;
      border: 1px solid #ebecfe;
      border-radius: 6px;
      padding: 16px;
      height: 586px;
      overflow: scroll;
      overflow-x: hidden;
      .arrowWrapper {
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 999;
        .innerArrowContainer {
          height: 100px;
          width: 100px;
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }

      @media only screen and (max-width: 767px) {
        height: unset;
      }

      .courseItemTopBarContainer {
        display: none;
      }
      @media only screen and (max-width: 767px) {
        padding: 16px;
        .courseItemsOutterWrapper {
          background: #ffffff;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
          border-radius: 6px;
          margin: 16px;
          padding: 12px;
        }

        .courseItemTopBarContainer {
          display: flex;
          align-items: center;
          padding-bottom: 12px;

          .leftIconBlock {
            margin-right: 12px;
            cursor: pointer;
          }
          .rightBlock {
            display: flex;
            flex-direction: column;
            .courseTitle {
              font-weight: 500;
              font-size: 14px;
              line-height: 19px;
              display: flex;
              flex-direction: column;
            }
            .courseLessonCount {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #6e7191;
            }
          }
        }
      }

      .courseItemsWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px 12px;
        background: #ffffff;

        @media only screen and (max-width: 767px) {
          background: #ffffff;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
          border-radius: 4px;
          padding: 14px 12px;
        }

        @include respond(tablet) {
          gap: 12px !important;
          grid-template-columns: 1fr !important;
        }

        // .haveSidebar{
        //   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
        //   border-radius: 4px;
        //   padding: 14px 12px 13px 12px;
        // }

        &.resourceContainer {
          grid-template-columns: 1fr 1fr;
          @media only screen and (max-width: 767px) {
            background: none;
            box-shadow: none;
            border-radius: unset;
            padding: unset;
          }
        }

        &.haveSidebar {
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    .courseItemsOutterWrapperRedesigned {
      flex: 1;
      height: 586px;
      overflow: scroll;
      overflow-x: hidden;
      .arrowWrapper {
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 999;
        .innerArrowContainer {
          height: 100px;
          width: 100px;
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }

      @media only screen and (max-width: 767px) {
        height: unset;
      }

      .courseItemTopBarContainer {
        display: none;
      }
      @media only screen and (max-width: 767px) {
        padding: 16px;
        .courseItemsOutterWrapper {
          background: #ffffff;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
          border-radius: 6px;
          margin: 16px;
          padding: 12px;
        }

        .courseItemTopBarContainer {
          display: flex;
          align-items: center;
          padding-bottom: 12px;
          position: relative;

          .filterIconSpecial {
            position: absolute;
            right: 0;
            padding: 10px;

            &:hover {
              cursor: pointer;
            }
          }

          .leftIconBlock {
            margin-right: 12px;
            cursor: pointer;
          }
          .rightBlock {
            display: flex;
            flex-direction: column;
            .courseTitle {
              font-weight: 500;
              font-size: 14px;
              line-height: 19px;
              display: flex;
              flex-direction: column;
            }
            .courseLessonCount {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #6e7191;
            }
          }
        }
      }

      .courseItemsWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px 12px;
        background: #ffffff;

        @media only screen and (max-width: 767px) {
          background: #ffffff;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
          border-radius: 4px;
          padding: 14px 12px;
        }

        @include respond(tablet) {
          gap: 12px !important;
          grid-template-columns: 1fr !important;
        }

        // .haveSidebar{
        //   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
        //   border-radius: 4px;
        //   padding: 14px 12px 13px 12px;
        // }

        &.resourceContainer {
          grid-template-columns: 1fr 1fr;
          @media only screen and (max-width: 767px) {
            background: none;
            box-shadow: none;
            border-radius: unset;
            padding: unset;
          }
        }

        &.haveSidebar {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }

  .noResultsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 35px;

    .noResultsContent {
      margin-top: 24px;
      text-align: center;

      .noResultTitle {
        font-size: 20px;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.75px;
        opacity: 0.85;
        color: #000000;
        font-weight: 900;
      }
      .noResultContent {
        color: #a0a3bd;
        font-size: 16px;
        font-weight: 500;
      }

      h4 {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #000000;
      }

      span {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #6a6a6a;
      }
    }
  }
}

.itemIcon {
  // display: inline-block;
  margin-right: 8px;
  @media only screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.857);
  }
}

.courseNavbarContainer {
  position: relative;
  display: flex;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  overflow: auto;
  font-family: $font-family;

  &.isSticky {
    position: sticky;
  }

  :global(.swiper) {
    width: 100%;
  }

  .navPrevBtn,
  .navNextBtn {
    position: absolute;
    top: 50%;
    display: inline-block;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 0px 20px 20px #ffffff;
    color: #423dcf;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    transform: translateY(-50%);
    z-index: 5;
    border: 1px solid #423dcf;

    &:global(.swiper-button-disabled) {
      display: none;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .navPrevBtn {
    left: 12px;
  }

  .navNextBtn {
    right: 12px;
  }
  // .NavigationbarCourseDashboard {
  .NavBarSlider {
    display: flex !important;
    align-items: center !important;
    @media only screen and (max-width: 767px) {
      width: auto !important;
    }
  }
  // }

  .menubarItem,
  .menubarItemActive,
  .menubarItemLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-family: $font-family;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #6e7191;
    text-align: center;
    cursor: pointer;
    padding: 20px 0px;

    @media only screen and (max-width: 767px) {
      .swiper-slide {
        display: flex;
        align-items: center;
      }
      padding: 12.09px 20px 12.09px 15px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .menubarItemActive {
    color: #423dcf;
    border-bottom: 2px solid #423dcf;
    .itemIcon {
      @include respond(mobile) {
        transform: scale(0.933) !important;
      }
    }
  }
  .itemText {
    overflow: hidden;
    white-space: nowrap;
  }

  .menubarItemLoading {
    .itemIcon {
      display: inline-block;
      height: 32px;
      width: 32px;
      border-radius: 5px;
      background: #f6f7f8;
      background-image: linear-gradient(
        to right,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 40%,
        #f6f7f8 100%
      );
      background-repeat: no-repeat;
      background-size: 800px 104px;
      animation: shimmer 2s infinite linear;
    }

    .itemText {
      display: inline-block;
      width: 75px;
      height: 22px;
      border-radius: 5px;
      background: #f6f7f8;
      background-image: linear-gradient(
        to right,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 40%,
        #f6f7f8 100%
      );
      background-repeat: no-repeat;
      background-size: 800px 104px;
      animation: shimmer 2s infinite linear;
    }

    @keyframes shimmer {
      0% {
        background-position: -468px 0;
      }

      100% {
        background-position: 468px 0;
      }
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  font-family: $font-family;
  position: absolute;
  top: -10px;
  left: -10px;
  display: flex;
  height: 84px;
  width: 84px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  background-image: url(../../assets/images/ComingsoonBg.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  @include respond(tablet) {
  background-image: url(../../assets/images/JoinNowMweb.svg);
  }
  justify-content: center;
  align-items: center;
  user-select: none;

  span {
    transform: rotate(-45deg) translateY(-12px);
  }

  @media screen and (max-width: 480px) {
    height: 48px;
    width: 48px;
    top: -6px;
    left: -6px;
    background-size: cover;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    span {
      transform: rotate(-45deg) translateY(-6px);
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  position: relative;
  display: block;

  .sliderSlide {
    height: auto;
    padding: 8px 0;

    > a {
      height: 100%;
      display: block;
      text-decoration: none;
    }
  }

  .navigationWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;
    z-index: 10;

    @include respond(mobile) {
      display: none;
    }

    .navPrevSlide,
    .navNextSlide {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 35px;
      background-color: #ffffff;
      border: 1px solid #423dcf;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      font-size: 18px;
      text-align: center;
      pointer-events: all;
      color: #423dcf;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
      }

      &:global(.swiper-button-disabled) {
        opacity: 0;
        visibility: hidden;
      }
    }

    .navNextSlide {
      transform: translateX(calc(100% + 10px));
    }

    .navPrevSlide {
      transform: translateX(calc(-100% - 10px));
    }
  }

  @media screen and (min-width: 601px) {
    &[data-slides='4'],
    &[data-slides='3'],
    &[data-slides='2'],
    &[data-slides='1'] {
      .navPrevSlide,
      .navNextSlide {
        display: none !important;
      }
    }
  }

  @media screen and (min-width: 481px) {
    &[data-slides='3'],
    &[data-slides='2'],
    &[data-slides='1'] {
      .navPrevSlide,
      .navNextSlide {
        display: none;
      }
    }
  }

  @media screen and (min-width: 351px) {
    &[data-slides='2'],
    &[data-slides='1'] {
      .navPrevSlide,
      .navNextSlide {
        display: none;
      }
    }
  }

  @media screen and (max-width: 350px) {
    &[data-slides='1'] {
      .navPrevSlide,
      .navNextSlide {
        display: none;
      }
    }
  }
}

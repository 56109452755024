@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
  width: 326px;
  max-width: 100%;
  border-radius: 8px;

  .questionStatusWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 16px 16px 12px;
    gap: 16px 16px;
    border-bottom: 1px solid #d9dbe9;
    max-height: 384px;
    overflow: scroll;

    .questionStatus {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .statusOverviewWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 16px 16px 16px;
    gap: 16px;
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.loaderPopupVisible {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  opacity: 1;
  animation: show 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;
}

.viewResultModal{
  background-color: rgba(0, 0, 0, 0.4);
  flex-direction: column;
  color: #fff;
  font-family: Avenir;
  .loadingText{
  margin-top: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  }
  .pleaseWaitText {
    margin-top: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.loaderPopupHidden {
  display: none;
}

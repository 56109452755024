@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1086px;
  width: 100%;
  padding: 25px;
  margin: 0 auto;
  font-family: $font-family;
  min-height: 50vh;

  .innerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contentIcon {
      display: inline-block;
      font-size: 46px;
      margin-bottom: 15px;
      color: courseDashboardColor('500');
    }

    .contentTitle {
      display: inline-block;
      font-size: 32px;
      font-weight: 800;
      text-transform: uppercase;
      text-align: center;
    }

    .loginBtn {
      display: block;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 15px;
      padding: 10px 25px;
      background-color: courseDashboardColor('700');
      color: #ffffff;
      border-radius: 10px;
      box-shadow: 2px 2px 5px 2.5px rgba(courseDashboardColor('700'), 0.25);

      .loginBtnIcon {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
}

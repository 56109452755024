@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper,
.wrapperVisible {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  padding: 160px 15px 15px;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.3s ease-out;

  &.middleModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
  &.modalContainerBg{
    background: #000;
  }

  .contentWrapper {
    position: relative;
    padding: 40px;
    background: #ffffff;
    box-shadow: 0px 3px 14px rgba(74, 58, 255, 0.03), 0px -2px 4px rgba(20, 20, 43, 0.12),
      0px 12px 44px rgba(20, 20, 43, 0.34);
    border-radius: 22px;
    font-family: Avenir, sans-serif;
    width: 690px;
    max-width: 100%;
    margin: auto;
    transform: translateY(-160px);
    transition: all 0.3s ease-out;

    .closeBtn {
      position: absolute;
      top: 20px;
      right: 28px;
      font-size: 24px;
      color: #757575;
      line-height: 1;
    }
    .closeBtnMiddle {
      position: absolute;
      top: -50px;
      right: 50%;
      font-size: 24px;
      color: #757575;
      line-height: 1;
      background: white;
      padding: 5px 9px;
      border-radius: 50%;
    }

    .content {
      display: flex;
    }
  }
  .closeBtnCornerOutside{
    position: fixed;
    top:42px;
    right: 42px;
    font-size: 24px;
  }

  @include respond(mobile) {
    .mwebContentWrapper {
      position: absolute;
      top: auto;
      left: 0;
      bottom: 0;
      border-radius: 8px 8px 0 0;
    }
  }
}

.wrapperVisible {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.4);

  .contentWrapper {
    transform: translateY(0px);
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.parentIcon {
  position: relative;
}

.childIcon {
  position: absolute;
  top: 0px;
  right: 5px;

  @include respond(mobile) {
    top: 22px;
    right: 20px;
  }
}

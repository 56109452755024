@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.transactionWrapper {
  margin: 25px;

  .tableWrapper {
    overflow-x: auto;

    table,
    th,
    td {
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      border-collapse: collapse !important; /* Single occurrence */
      padding: 10px;
      text-align: center;
      font-family: Avenir;
      width: 100%;
    }

    thead {
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-collapse: collapse !important; /* Single occurrence */
      background: #fff;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.5);

      .tableItem {
        font-weight: 600;
        text-align: start;
        min-width: 125px;
      }
    }

    .orderDetailsWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;

      .dateWrapper {
        padding: 10px 5px;
        width: 80px;

        .orderDate {
          border: 2px solid;
          padding: 5px;
          font-size: x-large;
          font-weight: 600;
          border-radius: 5px 5px 0 0;
          margin-bottom: 0;
        }

        .orderDateError {
          border-color: red;
          color: red;
        }

        .orderDateSuccess {
          border-color: #8ff703;
          color: #8ff703;
        }

        .orderMonthYear {
          border-width: 0 2px 4px;
          border-style: solid;
          border-radius: 0 0 5px 5px;
          margin-top: 0;
          padding: 0 5px;
          font-size: small;
        }

        .orderMonthYearError {
          border-color: red;
          color: red;
        }

        .orderMonthYearSuccess {
          border-color: #8ff703;
          color: #8ff703;
        }

        .toggleDisplayBlock {
          display: block;
        }

        .toggleDisplayNone {
          display: none;
        }

        .successBtn {
          margin-top: 5px;
          color: #8ff703;
          font-weight: 600;
          font-size: 15px;
        }

        .failureBtn {
          margin-top: 5px;
          color: red;
          font-weight: 600;
          font-size: 15px;
        }
      }
    }

    .detailsWrapper {
      padding: 10px;
      text-align: start;
      font-weight: 600;
      width: 250px;
      font-size: 13px;

      .orderId {
        color: rgba(0, 0, 0, 0.5);
      }

      .invoiceBtn {
        margin-top: 1rem;
        color: #423dcf;
        cursor: pointer;
      }
    }

    .courseAmt {
      font-weight: 800;
      text-align: start;
      font-size: 13px;
    }
  }
  .noImageContainer {
    text-align: center;

    .noTransactionsText {
      margin-top: 10px;
      font-size: 18px;
      line-height: 45px;
      font-family: Avenir;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 576px) {
  .transactionWrapper {
    width: 90vw;
    .tableWrapper {
      thead {
        font-size: 13px;
      }

      .detailsWrapper {
        width: 200px;
      }
    }
  }
}

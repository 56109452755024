@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  position: relative;
  display: block;
  max-width: 100%;

  @include respond(mobile) {
    display: none;
  }

  .navigationWrapper {
    position: absolute;
    bottom: 12px;
    right: 8px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    pointer-events: none;
    z-index: 10;

    .navPrevSlide,
    .navNextSlide {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      width: 34px;
      background-color: #ffffff;
      border: 1px solid #a6a0a0;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      font-size: 18px;
      text-align: center;
      color: #a6a0a0;
      pointer-events: all;
      cursor: not-allowed;
      transition: all 0.3s;

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
      }

      &:global(.swiper-button-disabled) {
        filter: grayscale(1);
        cursor: not-allowed;
      }
      &:not(:global(.swiper-button-disabled)) {
        color: #423dcf;
        border-color: #423dcf;
        cursor: pointer;
      }
    }
  }

  .sliderPagination {
    position: absolute;
    bottom: -18px !important;
    left: 0;
    display: inline-flex;
    justify-content: center;

    :global {
      .swiper-pagination-bullet {
        background-color: #d9d9d9;
        opacity: 1;
        transition: all 0.3s;

        &.swiper-pagination-bullet-active {
          background-color: #423dcf;
          box-shadow: 0 0 5px 1px rgba($color: #423dcf, $alpha: 0.7);
        }
      }
    }
  }

  .sliderSlide a {
    display: block;
    width: 100%;
    height: 160px;
  }

  .sliderSlide img {
    border-radius: 8px !important;
    overflow: hidden;
  }

  .sliderLink[href='/goal/[category]'] {
    cursor: default;
  }
}

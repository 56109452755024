@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.authModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  visibility: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
    user-select: none;
    transition: all 0.3s;
    z-index: -1;
  }

  &:global(.show) {
    visibility: visible;
    z-index: 9999999999 !important;

    .authModal {
      transform: translateX(0%);
    }

    &::before {
      opacity: 1;
      visibility: visible;
      user-select: all;
    }
  }
}

.authModal {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
  width: 478px;
  max-width: 100%;
  padding: 25px 40px 30px;
  margin-left: auto;
  transform: translateX(100%);
  transition: all 0.3s;

  :global {
    * {
      box-sizing: border-box;
    }

    button[type='submit']:disabled {
      opacity: 0.6;
    }

    .auth-modal--header {
      display: flex;
      font-size: 14px;
      font-family: 'Quicksand';
      line-height: 18px;
      color: #000000;
      margin-bottom: 1rem;
      margin-left: -23px;

      .auth-modal--header-close {
        display: inline-block;
        margin-right: 10px;
        color: #757575;
        font-size: 20px;
      }

      .auth-modal--header-title {
        font-weight: 500;
      }
    }

    .auth-modal--content {
      height: 100%;
      overflow: auto;
    }
  }

  .authTrouble {
    font-size: 13px;
    padding-top: 5px;
    line-height: 22px;
    font-weight: 300;
    text-align: center;

    a {
      color: #8db2e8;
      padding-left: 5px;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  font-family: $font-family;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  padding: 16px 29px 6px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

  &:hover {
    border: 1px solid #423dcf;
    border-style: solid;
    border-color: #423dcf;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }

  @media only screen and (max-width: 767px) {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    border: unset;
    box-shadow: none;
    border-radius: 0px;
    border-bottom: 1px solid #dbdbdb;
    padding: 0px 0px 6px 0px;
  }

  .leftWrapperBlock {
    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .rightImageBlock {
    display: none;
    @media only screen and (max-width: 767px) {
      display: flex;
      align-items: center;
    }
  }
  &:hover {
    .newResource {
      background: #423dcf;
      color: #fcfcfc;
    }
  }

  .newResource,
  .newResourceMobile {
    background: #eeeefb;
    border-radius: 4px;
    color: #423dcf;
    padding: 2px 4px;
    font-size: 10px;
    margin-top: 4px;
    display: inline-block;
  }

  .newResourceMobile {
    @media only screen and (min-width: 767px) {
      display: none;
    }
  }

  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    @media only screen and (max-width: 767px) {
      box-shadow: none;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    @media only screen and (max-width: 767px) {
      margin-bottom: 8px;
      margin-top: 4px;
    }

    .folderIndex {
      font-weight: 700;
      font-size: 28px;
      line-height: 35px;
      color: courseDashboardColor('200');

      @media only screen and (max-width: 767px) {
        display: none;
      }
    }

    .folderTopics {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.4);

      .newResource {
        display: none;
      }

      .newResource {
        @media only screen and (max-width: 767px) {
          margin-left: 8px;
        }
      }
    }
  }

  .content {
    margin-bottom: auto;
    .newResource {
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }

    .folderTitle {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }
  }

  .footer {
    margin-top: 4px;

    .newTopicsInfoWrapper {
      display: inline-flex;
      align-items: center;

      .newTopicsBadge {
        display: inline-block;
        padding: 1.5px 3px;
        color: courseDashboardColor('700');
        background: rgba(238, 129, 69, 0.1);
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.02em;
        margin-right: 4px;
      }

      .newTopicsInfo {
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
        display: flex;
        align-items: center;
        color: courseDashboardColor('700');
      }
    }
  }
}

.skeleton {
  font-family: $font-family;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  padding: 16px 29px 6px 16px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  transition: box-shadow 300ms;
  cursor: default;

  * {
    border-radius: 2.5px;
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .folderIndex {
      height: 20px;
      width: 80px;
      background: linear-gradient(-45deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
      background-size: 200%;
      animation-name: shimmer;
    }

    .folderTopics {
      height: 12px;
      width: 35px;
      background: linear-gradient(-45deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
      background-size: 200%;
      animation-name: shimmer;
    }
  }

  .content {
    height: 40px;
    width: 90%;
    background: linear-gradient(-45deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
    background-size: 200%;
    animation-name: shimmer;
    margin-bottom: 12px;
  }

  .footer {
    height: 18px;
    width: 75%;
    background: linear-gradient(-45deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
    background-size: 200%;
    animation-name: shimmer;
  }

  @keyframes shimmer {
    0% {
      background-position: 200%;
    }
    100% {
      background-position: 0%;
    }
  }
}

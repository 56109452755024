@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.rippleButton {
  position: relative;
  overflow: hidden;
  border: none;
  outline: none;
  background-color: #e0e0e0;
  color: #ffffff;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;

  .rippleEffect {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    transform: scale(0) translate(-50%, -50%);
    animation: ripple 1s linear;
    pointer-events: none;
    transform-origin: top left;
  }

  @keyframes ripple {
    to {
      transform: scale(6) translate(-50%, -50%);
      opacity: 0;
    }
  }
}

$font-family: 'Quicksand';

$very-small: 1rem;
$small: 1.2rem;
$normal: 1.4rem;
$normal-big: 1.6rem;
$medium: 1.8rem;
$large: 2rem;
$big: 2.2rem;
$medium-big: 2.4rem;
$very-big: 3.6rem;

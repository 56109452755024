@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper,
.wrapperSelected {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 10px 12px 12px;
  transition: border 0.4s;
  cursor: pointer;
  width: 286px;

  @media only screen and (max-width: 767px) {
    width: unset;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    right: 11px;
    top: 50%;
    height: 20px;
    width: 20px;
    background: transparent;
    transform: rotate(45deg) translateY(-50%);
    border-radius: 2px;
    border: 1px solid transparent;
    transition: right 0.4s;
    border-left-width: 0px;
    border-bottom-width: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &::before {
    transition: right 0.3s;
  }

  &::after {
    border-color: courseDashboardColor('700');
    z-index: -2;
  }

  &:hover {
    background: courseDashboardColor('50');
    border: 1px solid courseDashboardColor('700');
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);

    &::before {
      background: courseDashboardColor('50');
    }

    .newResource {
      background: #423dcf;
      color: #fcfcfc;
    }
  }
  // mweb styling start
  .rightImageBlock {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    .rightImageBlock {
      display: block;
    }
    .rightImageBlock {
      margin-left: 20px;
    }
    .wrapperInner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &::after {
      display: none;
    }
  }
  // mweb styling End
  .newResource {
    background: #eeeefb;
    border-radius: 4px;
    color: #423dcf;
    padding: 2px 4px;
    font-size: 10px;
  }

  .content {
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .footer {
    margin-top: 4px;
    @media only screen and (max-width: 767px) {
      margin-top: 8px;
    }

    .lessonsCount {
      display: inline-block;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.4);
      margin-right: 12px;
    }

    .newTopicsInfoWrapper {
      display: inline-flex;
      align-items: center;
      margin-top: 5px;

      .newTopicsBadge {
        display: inline-block;
        padding: 1.5px 3px;
        color: courseDashboardColor('700');
        background: rgba(238, 129, 69, 0.1);
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.02em;
        margin-right: 4px;
      }

      .newTopicsInfo {
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
        display: flex;
        align-items: center;
        color: courseDashboardColor('700');
      }
    }
  }
}

.wrapperSelected {
  background: courseDashboardColor('50');
  border: 1px solid courseDashboardColor('700');
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);

  &::after,
  &::before {
    right: -3.58px;
    background: courseDashboardColor('50');
  }

  &::before {
    right: -2.2px;
    z-index: 3;
    transition: right 0.4s;
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: grid;
  font-family: 'Avenir', sans-serif;
  gap: 0 15px;
  grid-template-columns: minmax(0, 1fr);

  @include respond(tablet) {
    margin: 0;
    padding: 17px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: 1086px;
    margin: 25px auto;

    section:first-child {
      margin-top: -4px;
    }
  }

  .bottomDescriptionWrapper {
    font-size: 12px;
    padding: 5px;
    background-color: #f7f7f7;

    .bottomDescriptionContent {
      max-width: 1086px;
      margin: 24px auto;

      h2 {
        color: #4e4b66;
        font-size: 12px;
        font-weight: 800;
        line-height: 18px;
        margin-bottom: 4px;
      }

      p {
        color: #6e7191;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 12px;
      }
    }
  }
}

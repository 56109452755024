@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px;
  gap: 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.3s;
  cursor: pointer;
  max-width: 262px;
  height: 100%;

  @include respond(mobile) {
    min-height: 311px;

    &.showPrice {
      min-height: 356px;
    }
  }

  @include respond(mobile) {
    min-height: auto !important;
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  }

  @include respond(sm-mobile) {
    max-width: none;
  }

  &.responsiveWrapper {
    @include respond(sm-mobile) {
      flex-direction: row;
      gap: 5px;
    }
  }

  &.haveBatchValue {
    .contentWrapper .productTitle {
      -webkit-line-clamp: 2 !important;
    }
  }

  .thumbnailWrapper {
    display: grid;
    grid-template-columns: max-content 1fr 15px;
    grid-template-rows: 1fr 14px;
    grid-template-areas:
      'course-info course-info course-language'
      'course-type . .';
    overflow: hidden;
    background: linear-gradient(252.98deg, #f4a78a 0%, #cc6b47 97.6%);
    border-radius: 8px;
    padding: 8px 10px;
    height: 114px;
    box-shadow: 0 0 5px 2px #e5e4e2;

    .thumbnailInfo {
      grid-area: course-info;
      display: flex;
      flex-direction: column;
      margin-bottom: auto;
      padding-right: 12px;

      .thumbnailTitle {
        display: -webkit-box;
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .thumbnailYear {
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
      }

      .thumbnailTitle,
      .thumbnailYear {
        color: #ffffff;
      }
    }

    .thumbnailCourseLanguage {
      grid-area: course-language;
      display: inline-block;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      padding: 1.6px;
      font-weight: 600;
      font-size: 7.82609px;
      line-height: 12px;
      text-align: center;
      color: #ffffff;
      height: 16px;
      user-select: none;
    }

    .thumbnailCourseType {
      grid-area: course-type;
      display: inline-block;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      padding: 1.5px 6px;
      font-weight: 500;
      font-size: 8px;
      line-height: 11px;
      color: #ffffff;
    }
  }

  .thumbnailImageWrapper {
    position: relative;
    width: 246px;
    max-width: 100%;
    overflow: hidden;
    align-self: center;
    border: 1.36px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    aspect-ratio: 16 / 10;

    @include respond(mobile) {
      height: 102px;
      width: 100%;
      flex: 0 0 150px;
    }

    .thumbnailImage {
      width: 100%;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;

    .productTitle {
      display: -webkit-box;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.16px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #374a59;
      word-break: break-word;
      margin-bottom: auto;

      @include respond(mobile) {
        color: #0f172a;
        font-size: 13px;
        font-weight: 900;
        line-height: 150%;
      }
    }

    .productPriceWrapper {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 0 0 8px;
      margin-top: auto;

      .desktopPrice,
      .mobilePrice {
        display: flex;
        gap: 6px;
        align-items: center;
      }

      .mobilePrice {
        display: none;
      }

      @include respond(mobile) {
        .mobilePrice {
          display: flex;
        }

        .desktopPrice {
          display: none;
        }
      }

      .productPrice {
        font-weight: 800;
        font-size: 16px;
        line-height: 30px;
        color: #262338;

        @include respond(mobile) {
          font-size: 15px;

          svg {
            font-size: 12px;
          }
        }
      }

      .productPriceNet {
        position: relative;
        font-weight: 800;
        font-size: 14px;
        line-height: 22px;
        color: #a6a0a0;

        @include respond(mobile) {
          font-size: 12px;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          height: 1.5px;
          width: 110%;
          background-color: #a6a0a0;
          transform: translate(-5%, -50%);
          border-radius: 2px;
        }
      }

      .productPrice,
      .productPriceNet {
        svg {
          display: inline-block;
          margin-right: 4px;
          font-size: 12px;
        }
      }

      .priceDiscount {
        font-weight: 800;
        font-size: 14px;
        line-height: 22px;
        color: #12b76a;

        @include respond(mobile) {
          font-size: 12px;
        }
      }
    }

    .productLiveClassInfo {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 5px 0;

      .productLiveClassDate {
        color: #6e7191;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;

        @include respond(mobile) {
          font-size: 9px;
          font-weight: 800;
          line-height: 120%;
        }

        b {
          color: #008a00;
          font-weight: 800;
        }
      }

      .productLiveClassTime {
        color: #6e7191;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;

        @include respond(mobile) {
          font-size: 9px;
          font-weight: 500;
          line-height: 120%;
        }

        b {
          font-weight: 800;
        }
      }
    }

    .productLiveClassInfo + .productPriceWrapper {
      margin-top: 0;
    }

    @include respond(mobile) {
      .productLiveClassInfo + .productPriceWrapper {
        display: none !important;
      }
    }

    .productOffer {
      display: inline-flex;
      align-items: center;
      color: #eaac30;
      border-top: 0.5px solid rgba(0, 0, 0, 0.1);
      padding: 12px 0 8px;
      margin-top: auto;

      .productOfferIcon {
        display: inline-block;
      }

      .productOfferTitle {
        display: -webkit-box;
        font-weight: 800;
        font-size: 12px;
        line-height: 14px;
        margin-left: 4px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .productPriceWrapper + .productOffer {
      margin-top: 0;
    }

    .productTitle + .productOffer {
      margin-top: 8px;
    }
  }

  &:not(.showPrice) {
    .productTitle {
      -webkit-line-clamp: 3;
    }
  }
}

.responsiveWrapper {
  .contentWrapper {
    @include respond(mobile) {
      .productOffer {
        padding: 6px 0 0;
        color: #2aa8f8;

        .productOfferIcon {
          display: none;
        }
      }
    }
  }
}

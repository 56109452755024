@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.notificationWrapper {
  position: relative;
  font-family: Avenir, sans-serif;
  display: flex;
  padding: 15px;
  background-color: #FFFFFF;
  gap: 12px;
  transition: all 0.3s;
  text-decoration: none;

  &.isLink {
    cursor: pointer;
  }

  &:hover {
    background-color: #F7F7FC;
  }

  .thumbnailWrapper {
    .thumbnail {
      position: relative;
      width: 88px;
      height: 58px;
      border-radius: 4.549px;
      padding: 4px;
      overflow: hidden;
    }
  }

  .notificationContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-right: 39px;

    .notificationTitle {
      color: #262338;
      font-size: 14px;
      font-weight: 800;
      line-height: 150%;
      max-height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .notificationCourse {
      color: #6E7191;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;

      span {
        color: #423DCF;
      }
    }

    .notificationAuthor {
      color: #4E4B66;
      font-size: 12px;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .time {
    color: #A0A3BD;
    font-size: 10px;
    font-weight: 500;
    line-height: 120%;
    white-space: nowrap;
    position: absolute;
    top: 16px;
    right: 15px;
    pointer-events: none;
  }

  .readIndicator {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 50%;
    right: 15px;
    height: 12px;
    width: 12px;
    background-color: rgba(66, 61, 207, 0.15);
    border-radius: 6px;
    transform: translateY(-50%);

    &::before {
      content: '';
      display: inline-block;
      height: 6px;
      width: 6px;
      background-color: rgba(66, 61, 207, 1);
      border-radius: 3px;
    }
  }

  &.isNew {
    background: #EBECFE;

    .readIndicator {
      display: flex;
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.examPreferenceScreen {
  font-family: $font-family;
  display: flex;
  flex-direction: column;
  height: 100%;

  .mainSubtitle {
    font-family: $font-family;
    font-weight: 400;
    font-size: $normal;
    line-height: 18px;
    color: #979797;
  }

  .goalSearchWrapper {
    background: #ffffff;
    padding: 12px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    margin: 12px 0px 16px;

    .goalSearchIconWrapper {
      font-size: $normal-big;
      text-align: center;
      color: #dadada;
    }

    .goalSearchInput {
      font-size: 14px;
      line-height: 18px;
      margin-left: 18px;
      font-weight: 400;
      cursor: text;
    }
  }

  .goalsWrapper {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    gap: 16px;
    &.isGoalsAvailable{
      overflow: hidden;
    }

    .popularGoals {
      border-bottom: 2px solid #ededed;
    }

    .goalsWrapperTitle {
      color: #423DCF;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }

    .goalsListWrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 12px;
      margin-bottom: 16px;

      .goalItem {
        display: flex;
        align-items: center;
        padding: 12px;
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.36);
        cursor: pointer;

        &.highlighted,
        &:hover {
          border: 1px solid #423DCF;
        }        

        .goalItemIconWrapper {
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #ededed;
          line-height: 1;
          height: 24px;
          width: 24px;
          border-radius: 50%;

          .goalItemIcon {
            border-radius: 50%;
          }
        }

        .goalItemContent {
          display: flex;
          margin-left: 14px;

          .goalItemName {
            font-family: Quicksand;
            font-size: $normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }
  }

  .noResultsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    margin-top: 15px;
    overflow: hidden;

    .noResultsLabel {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
}

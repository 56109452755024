@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  border-radius: 8px;
  background: #F6F5F5;
  max-width: 400px;
  width: 100%;
  gap: 12px;
  padding: 8px 16px;
  align-items: center;
  border: 1px solid #F6F5F5;
  transition: all 0.3s;
  cursor: pointer;

  .indexWrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    min-width: 24px;
    background-color: #EEEEEE;
    border-radius: 12px;
    color: #787A80;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    transition: all 0.3s;
  }

  &:hover, &.isSelected {
    border-color: #423DCF;

    .indexWrapper {
      background-color: #423DCF;
      color: #FFFFFF;
    }
  }

  &.isRightAnswer {
    border-color: #008A00;

    .indexWrapper {
      background-color: #008A00;
      color: #FFFFFF;
    }
  }

  &.isWrongAnswer {
    border-color: #EB001D;

    .indexWrapper {
      background-color: #EB001D;
      color: #FFFFFF;
    }
  }

  &.readOnly {
    pointer-events: none;
  }

  .labelWrapper {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;

    br {
      display: none;
    }
  }
}

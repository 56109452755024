@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.emailModalContent {
  display: flex;
  align-items: center;
  flex-direction: column;

  .svgSettings {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);

    .linearStop1 {
      stop-color: #423DCF;
    }

    .linearStop2 {
      stop-color: rgba(241, 158, 56, 0.94);
    }
  }

  .envelopeIconWrapper {
    display: inline-block;
    padding: 25px 28px;
    background-color: rgba($color: #423DCF, $alpha: 0.1);
    border-radius: 34px;
    font-size: 100px;
    line-height: 1;

    path {
      fill: url(#envelope-linear);
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    margin: 16px 0px;

    .contentTitle {
      font-size: 28px;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: 0px;
      text-align: center;
    }

    .contentSubTitle {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0px;
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .inputWrapper {
    display: flex;
    background: #ffffff;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
    border-radius: 12px;
    flex: 1 0 100%;
    width: 100%;
    padding: 10px 12px;

    .inputGroup {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 4px 8px;

      .inputLabel {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #868e96;
      }

      .emailInput {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
        margin-top: 6px;
        cursor: text;
      }
    }

    .submitButton {
      display: inline-block;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      background: #423DCF;
      border-radius: 12px;
      color: #ffffff;
      padding: 15px 75.5px;
      transition: all 0.3s;

      &:hover {
        background: darken($color: #423DCF, $amount: 5);
      }
    }

    @include respond(tablet) {
      flex-direction: column;
      border: none;
      box-shadow: none;
      padding: 0px;

      .inputGroup {
        border: 1px solid #eff0f7;
        box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
        padding: 10px 12px;
        border-radius: 12px;
      }

      .submitButton {
        margin-top: 15px;
        text-align: center;
      }
    }
  }

  .emailModalError {
    display: block;
    width: 100%;
    color: red;
    font-size: 12px;
    margin-top: 8px;
    padding-left: 5px;
  }
}

.successModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .successContent {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 26px;

    h6 {
      display: inline-block;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 4px;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #64748b;
    }
  }

  .successBtn {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    background: #423DCF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 7px;
    color: #ffffff;
    padding: 8px 15px;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.4s;

    &:hover {
      background: darken($color: #423DCF, $amount: 5);
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px !important;
  font-family: 'Avenir';
  @include respond(ipad) {
    padding: 12px !important;
  }

  .notFoundImg {
    display: block;
    margin-bottom: 24px;
    @include respond(ipad) {
      margin-bottom: 16px;
    }
  }

  .notFoundTitle {
    display: inline-block;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0.75px;
    margin-bottom: 12px;
    @include respond(ipad) {
      font-size: 14px;
      text-align: center;
    }
  }

  .notFoundContent {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: #a0a3bd;
    text-align: center;
    @include respond(ipad) {
      font-size: 14px;
      text-align: center;
    }
  }
}

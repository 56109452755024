@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.modalContent {
  background-color: white;
  width: 460px;
  padding: 28px;
  border-radius: 8px;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 19px !important;
  line-height: 25.95px;
  .NoVideoButton {
    font-family: 'Avenir';
    margin-top: 18px;
    float: right;
    color: white;
    height: 40px;
    width: 96px;
    border-radius: 8px;
    display: flex;
    font-size: 13px;
    font-weight: 900;
    justify-content: center;
    align-items: center;
    background-color: #423dcf;
  }
  .modalContentText {
    height: 52px;
  }
  @media only screen and (max-width: 767px) {
    width: 328px;
    font-size: 15px !important;
    padding: 24px !important;
  }
}

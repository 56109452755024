@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.paginationContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  margin: 25px 0;

  .btn {
    font-weight: 500;
    background: #423dcf;
    color: #fff;
    padding: 8px 20px;
    border-radius: 6px;
  }

  .btnDisabled {
    border-radius: 6px;
    padding: 8px 20px;
    cursor: default;
  }

  .paginationText {
    font-family: Avenir;
    font-size: 13px;
    font-weight: 500;

    span {
      color: #423dcf;
    }
  }
}

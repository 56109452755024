@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.accordion {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  .accordionHeader {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;

    .accordionHeaderTitle {
      font-weight: 800;
      font-size: 14px;
      line-height: 18px;
      color: #2f2f2f;
    }

    .accordionHeaderIcon {
      display: inline-block;
      font-size: 14px;
      color: #423dcf;
    }
  }

  .accordionContentWrapper {
    display: grid;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6d6d6d;
    grid-template-rows: 0fr;
    padding: 0 15px;
    transition: grid-template-rows 300ms, padding 150ms 50ms, margin 150ms 50ms;

    &.accordionActive {
      grid-template-rows: 1fr;
      margin-top: -10px;
      padding: 0 15px 10px;
    }

    > .accordionContent {
      overflow: hidden;
      transition: grid-template-rows 300ms, padding 150ms, margin 150ms;
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.aclWrapper {
  font-family: 'Quicksand';

  * {
    font-family: 'Quicksand';
  }

  .aclBanner {
    height: 150px;
    border-radius: 2px;
    background-image: url('../../../assets/images/title_pattern.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #513980;
    position: relative;

    .title {
      font-size: 35px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 700;
      text-align: center;
    }
    @media screen and (max-width: 600px) {
      height: 100px;
      .title {
        font-size: 20px;
      }
    }
  }

  .aclList {
    width: 80%;
    margin: 3.2rem auto;
    display: flex;
    overflow-x: auto;
    .aclCatWrapper {
      cursor: pointer;
      white-space: nowrap;
      margin: 1.6rem;
      padding: 0.8rem 2.4rem;
      color: #513980;
      font-weight: 500;
      font-size: 14px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-bottom: 3px solid white;
    }
    .active {
      @extend .aclCatWrapper;
      border-bottom: 3px solid rgb(255, 132, 119) !important;
      color: #0d6efd !important;
    }
    @media screen and (max-width: 600px) {
      width: 96%;
    }
  }

  .aclSubListWrapper {
    width: 80%;
    margin: 3.2rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 260px));
    grid-auto-rows: auto;
    grid-gap: 2rem;
    padding: 1.6rem 0;
    @media screen and (max-width: 600px) {
      width: 96%;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1.6rem;
      font-size: 13px;
    }

    .subListItemWrapper {
      height: 9.6rem;
      display: flex;
      align-items: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        height: 8rem;
      }

      .text {
        margin: 0 0.8rem;
        font-size: 14px;
      }

      &:nth-child(2n + 1) {
        border-left: 7px solid #879dff;
      }
      &:nth-child(2n) {
        border-left: 7px solid #f285d0;
      }
      &:nth-child(3n) {
        border-left: 7px solid #74f296;
      }
      &:nth-child(3n + 1) {
        border-left: 7px solid #ff7a72;
      }
    }
  }
}

.accWrapper {
  width: 80%;
  margin: 3.2rem auto;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-column-gap: 2.4rem;
  @media screen and (max-width: 600px) {
    width: 96%;
    grid-template-columns: 1fr;
    grid-row-gap: 2.4rem;
  }
  .accDrpdnWrapper {
    display: none;
    @media screen and (max-width: 600px) {
      display: block;
    }
    select {
      display: block;
      width: 100%;
      height: 38px;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.4rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  .accLeft {
    padding: 1.6rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @media screen and (max-width: 600px) {
      display: none;
    }
    .accLeftItem {
      padding: 0.8rem;
      cursor: pointer;
      color: #513980;
      transition: all 0.2s ease-in-out;
      font-size: 14px;
    }
    .active {
      color: #0d6efd;
      background: #f5fbff;
    }
  }
  .accRight {
    padding: 1.6rem 0.8rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow-y: auto;
    font-size: 14px;
  }
}
.accBanner {
  height: 150px;
  border-radius: 2px;
  background-image: url('../../../assets/images/title_pattern.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #513980;
  position: relative;
  @media screen and (max-width: 600px) {
    height: 100px;
  }

  .title {
    font-size: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
}

.aclList {
  width: 80%;
  margin: 3.2rem auto;
  display: flex;
  overflow-x: auto;
  .aclCatWrapper {
    cursor: pointer;
    white-space: nowrap;
    margin: 1.6rem;
    padding: 0.8rem 2.4rem;
    color: #513980;
    font-weight: 500;
    font-size: 14px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-bottom: 3px solid white;
  }
  .active {
    @extend .aclCatWrapper;
    border-bottom: 3px solid rgb(255, 132, 119);
    color: #0d6efd;
  }
  @media screen and (max-width: 600px) {
    width: 96%;
  }
}

.accOverlapWrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 38rem;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
  z-index: 9999999999;
  @media screen and (max-width: 600px) {
    top: 30rem;
  }

  .accSubcategories {
    background: #fff;
    width: 80%;
    margin: 0 auto;
    max-height: 400px;
    padding: 1.6rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    overflow-y: auto;
    @media screen and (max-width: 600px) {
      max-height: 250px;
      width: 96%;
      padding: 1.6rem 0.8rem;
    }

    .close {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.5;
      text-align: right;
      span {
        font-size: 20px;
        cursor: pointer;
      }
    }
    .accSubcatWrapper {
      margin-top: 1.6rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 245px));
      grid-auto-rows: auto;
      grid-gap: 3.2rem;
      padding: 1.6rem 0;
      @media screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1.6rem;
      }

      .subListItemWrapper {
        height: 8rem;
        display: flex;
        align-items: center;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        cursor: pointer;
        @media screen and (max-width: 600px) {
          font-size: 12px;
        }

        .text {
          margin: 0 0.8rem;
          font-size: 14px;
        }

        &:nth-child(2n + 1) {
          border-left: 7px solid #879dff;
        }
        &:nth-child(2n) {
          border-left: 7px solid #f285d0;
        }
        &:nth-child(3n) {
          border-left: 7px solid #74f296;
        }
        &:nth-child(3n + 1) {
          border-left: 7px solid #ff7a72;
        }
      }
    }
  }
}

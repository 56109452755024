@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.bestSellingCourses {
  @include respond(mobile) {
    > div {
      display: none;

      &:nth-child(-n + 3) {
        display: flex;
      }
    }
  }
}

.sectionSeeAllLink {
  display: none;
  color: #423dcf;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  text-decoration: none;
  white-space: nowrap;

  @include respond(mobile) {
    display: inline-block;
    align-self: flex-end;
    margin-bottom: 13px;
  }
}

.viewMoreModal {
  padding: 0;
  margin: 0;

  .viewMoreModalHeader {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    > span {
      display: -webkit-box;
      font-family: Avenir;
      color: #000 !important;
      font-size: 13px !important;
      line-height: 1 !important;
      font-weight: 300 !important;
      order: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    button {
      position: static !important;
      margin-right: 10px;
      color: #423dcf !important;
      font-size: 20px !important;
      order: 1;
    }
  }

  .viewMoreModalContentWrapper {
    border-radius: 0;
    height: 100%;
    max-height: 100vh;
    width: 100%;
    padding: 16px;
    overflow-y: scroll;

    .viewMoreModalContent {
      display: flex;
      flex-direction: column;
      gap: 15px 0px;
    }
  }
}

.faqSection {
  margin-bottom: 12px;
}

.bottomDescriptionWrapper {
  font-size: 12px;
  padding: 5px;

  h2 {
    margin-bottom: 4px;
  }

  p {
    margin-bottom: 8px;
  }
}

.supportBanner {
  display: none;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  padding: 13px 9px;
  background-size: 110%;
  background-position: center;
  cursor: pointer;

  @include respond(mobile) {
    display: flex;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;
    flex: 1;

    .title {
      color: #2f2f2f;
      font-size: 13px;
      font-weight: 900;
      line-height: 120%;
    }

    .subTitle {
      color: #000;
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

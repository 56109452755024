@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  align-items: center;
  background: #F8F7FC;
  padding: 8px 16px;
  border-radius: 8px;
  width: 100%;
  max-width: 326px;
  border: 1px solid #D9DBE9;

  .actionBtn {
    line-height: 30px;
    font-size: 32px;

    &:disabled {
      opacity: 0.1;
      cursor: not-allowed;
    }
  }

  .currentTime {
    display: inline-block;
    margin-left: 8px;
    color: #423DCF;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
  }

  .actionsWrapper {
    display: flex;
    margin-left: auto;
    gap: 8px;

    .actionBtn {
      display: inline-block;
      border-radius: 20px;
      background: transparentize(#A996FF, 0.8);
      font-size: 14px;
      line-height: 20px;
      font-weight: 800;
      padding: 2px 9px;
      cursor: default;

      &.increase {
        color: #067306;
      }

      &.decrease {
        color: #E61B00;
      }
    }
  }

  &.isSolution {
    .actionsWrapper {
      margin: 0 auto;
    }
  }
}

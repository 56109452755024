@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: inline-flex;
  width: 46px;
  height: 24px;
  padding: 2px;
  align-items: center;
  background-color: #A0A3BD;
  border-radius: 40px;
  transition: all 0.3s;
  cursor: pointer;

  .label {
    color: #FFFFFF;
  }

  .input {
    display: none;
  }

  .switchBtn {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
    animation: switch-off 0.3s forwards;
  }

  &:has(.input:checked) {
    background-color: #423DCF;

    .switchBtn {
      transform: translateX(22px);
      animation: switch-on 0.3s forwards;
    }
  }

  @keyframes switch-on {
    0% {
      width: 20px;
      transform: translateX(0px);
    }

    75% {
      width: 100%;
      transform: translateX(0px);
    }

    100% {
      width: 20px;
      transform: translateX(22px);
    }
  }

  @keyframes switch-off {
    0% {
      width: 20px;
      transform: translateX(22px);
    }

    75% {
      width: 100%;
      transform: translateX(0px);
    }

    100% {
      width: 20px;
      transform: translateX(0px);
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 33px;

  .accordionExpander {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) -143.74%, rgba(217, 217, 217, 0) 59.37%);

    &.isExpended {
      position: relative;
    }

    .accordionExpandBtn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 35px;
      border: 1px solid #423dcf;
      border-radius: 50%;
      background-color: #ffffff;
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
@function random-rgb() {
  @return rgb(random(255), random(255), random(255));
}

.wrapper {
  position: relative;
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .sliderSlide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    overflow: hidden;
    width: fit-content !important;

    .sliderItem {
      position: relative;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #6e7191;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;

      &::before {
        content: '';
        position: absolute;
        bottom: -24px;
        left: -10%;
        width: 120%;
        height: 8px;
        background: #423dcf;
        border-radius: 4px;
        transition: all 0.3s;
      }

      &:hover {
        color: #262338;
      }

      &.active {
        color: #423dcf;
        font-weight: 500;
        font-size: 18px;

        &::before {
          bottom: -16px;
        }
      }
    }
  }

  .navigationWrapper {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
    pointer-events: none;
    z-index: 10;

    .navPrevSlide,
    .navNextSlide {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 35px;
      background-color: #ffffff;
      border: 1px solid #423dcf;
      border-radius: 50%;
      font-size: 18px;
      pointer-events: all;
      color: #423dcf;
      transition: all 0.3s;
      cursor: pointer;

      &:global(.swiper-button-disabled) {
        opacity: 0;
        visibility: hidden;
      }
      @include respond(tablet) {
        display: none;
      }
    }

    .navPrevSlide {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), -20px 0 5px 1px #fff;

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25), -20px 0 5px 1px #fff;
      }
    }

    .navNextSlide {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 20px 0 5px 1px #fff;

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25), 20px 0 5px 1px #fff;
      }
    }
  }

  &.colored {
    .navigationWrapper {
      .navPrevSlide {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), -20px 0 5px 10px #fff;

        &:hover {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.25), -20px 0 5px 10px #fff;
        }
      }

      .navNextSlide {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 20px 0 5px 10px #fff;

        &:hover {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.25), 20px 0 5px 10px #fff;
        }
      }
    }

    .sliderSlide {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 5px;
      width: fit-content !important;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }

      .sliderItem {
        padding: 8px 24px;
        font-weight: 400;
        font-size: 14px;
        color: #6e7191;
        border-radius: 4px;
        text-decoration: none;
        white-space: nowrap;
        border: 1px solid transparent;
        transition: all 0.3s;
        cursor: pointer;

        &.haveUppercase {
          padding: 10px 24px 6px;
        }

        &.active {
          font-size: 20px;
          font-weight: 500;

          &.haveUppercase {
            padding: 8px 24px 4px;
          }
        }

        &::before {
          display: none;
        }

        &:hover,
        &.active {
          color: #262338;
        }
      }

      @for $i from 0 to 50 {
        &:nth-child(#{$i}) .sliderItem {
          $rgb-color: random-rgb();

          line-height: 1;
          background: transparentize($color: $rgb-color, $amount: 0.7);
          &.active {
            border-color: $rgb-color;
          }
        }
      }
    }
  }
}

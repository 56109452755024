@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  user-select: none;

  .header {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;

    .questionsWrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      .questionsCount {
        height: 24px;
        width: 24px;
        line-height: 24px;
        text-align: center;
        background-color: #eeeeee;
        border-radius: 12px;
      }

      span {
        color: #979797;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
    }

    .markForReview {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      span {
        color: #262338;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }

      svg {
        font-size: 24px;
      }
    }
  }

  .question {
    color: #4e4b66;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }

  .answerWrapper {
    margin-top: 18px;

    .answers {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 12px;
    }

    .inputBox {
      width: 420px;
      background-color: #efefef;
      padding: 10px 15px;
      border-radius: 3px;
      font-size: 16px;
    }

    .answerInputWrapper {
      display: flex;
      flex-direction: column;

      .correctAnswerWrapper,
      .wrongAnswerWrapper {
        display: inline-flex;
        grid-gap: 5px;
        font-size: 14px;
      }

      .correctAnswerWrapper {
        color: green;
      }

      .wrongAnswerWrapper {
        color: red;
      }
    }
  }

  .errorMessage {
    display: inline-block;
    margin-top: 15px;
    font-size: 14px;
    color: red;
  }

  .explanationWrapper {
    margin: 3rem 0rem;

    .explanationLabel {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: #14142b;
    }

    .explanationValue {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #4e4b66;
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  padding: 13px;
  border: 1px solid rgba(66, 61, 207, 0.4);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  width: 100%;
  height: 169px;
  max-width: 341px;
  border-radius: 7.5px 25px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;

    .userInfoWrapper {
      display: flex;
      align-items: center;

      .userImageWrapper {
        display: block;
        height: 76px;
        width: 76px;
        border-radius: 150px;
        overflow: hidden;
        flex-shrink: 0;
        flex-grow: 1;
        filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.15));
      }

      .userInfo {
        display: flex;
        flex-direction: column;
        margin-left: 7px;
        padding-right: 5px;

        .userInfoName {
          display: inline-block;
          font-weight: 500;
          font-size: 17px;
          line-height: 120%;
          color: #423dcf;
          padding-bottom: 3px;
        }

        .userInfoRank {
          display: -webkit-box;
          font-weight: 500;
          font-size: 11px;
          line-height: 120%;
          color: #a6a0a0;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }

    .userPlayIcon {
      color: #423dcf;
      height: 32px;
      border-radius: 50%;
    }
  }

  .userTestimonial {
    display: -webkit-box;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #2f2f2f;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

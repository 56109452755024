@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 12px;

  @include respond(tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include respond(mobile) {
    grid-template-columns: 1fr 1fr;
  }

  @include respond(sm-mobile) {
    grid-template-columns: 1fr;
  }

  @include respond(mobile) {
    > div[class^='Product_wrapper'] {
      display: none;

      &:nth-child(-n + 4) {
        display: flex;
      }
    }

    > a[href^='/course-detail/'] {
      display: none;

      &:nth-child(-n + 4) {
        display: block;
      }
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.notificationBellOuterWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  .bellIcon :global(*[class$="enabled"]) {
    visibility: hidden;
  }

  .notificationBellWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 6px;

    .notificationBell {
      position: absolute;
      top: -5px;
      left: -5px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 24px;
      background: #EBECFE;
      color: #423DCF;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
      border: 1px solid #EBECFE;
      font-size: 20px;
      pointer-events: all;
      cursor: pointer;
      transition: all 0.3s;

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -6px;
        display: inline-block;
        height: 0;
        width: 0;
        border: 5px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.70);
        transform: translateX(-40%) translateY(10px);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;
      }

      &::after {
        content: "Notify me";
        position: absolute;
        left: 5px;
        top: calc(100% + 5.7px);
        border-radius: 4px;
        padding: 4px 8px;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(2.5px);
        color: #FCFCFC;
        font-size: 12px;
        font-weight: 800;
        line-height: 18px;
        white-space: nowrap;
        transform: translateY(10px);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;
      }

      &:hover {
        border-color: #423DCF;

        &::before {
          transform: translateX(-40%) translateY(0);
          visibility: visible;
          opacity: 1;
        }

        &::after {
          transform: translateY(0);
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .notificationInfoBar {
    position: absolute;
    bottom: 0;
    left: 50%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 2px 8px;
    border-radius: 4px;
    background-color: #D9F3F0;
    max-width: max-content;
    gap: 4px;
    transform: translate(-50%, 50%);
  }

  &.isSubscribed {
    .notificationBell {
      background-color: #CFE4E1;
      color: #11766A;
      border-color: #CFE4E1 !important;

      &::after {
        content: "Click to turn off the notifcations";
      }
    }

    .bellIcon :global(*[class$="enabled"]) {
      visibility: visible;
    }

    .bellIcon :global(*[class$="disabled"]) {
      visibility: hidden;
    }
  }
}

.notificationAlertModal {
  font-family: Avenir, sans-serif;

  .modalHeader {
    color: #14142B;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 32px;
    letter-spacing: 0.25px;
    padding-bottom: 8px;
    border-bottom: 1px solid #D9DBE9;
    margin-bottom: 12px;
  }

  .contentWrapper {
    border-radius: 10px;
    padding: 20px 20px 24px 20px;
    max-width: 658px;

    .content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .modalNote {
        color: #14142B;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.25px;
      }

      .actionsWrapper {
        display: flex;
        gap: 20px;
        flex-direction: column;
        border-radius: 6px;
        background: #ECEEFE;
        padding: 16px 12px;

        .action {
          display: flex;
          justify-content: space-between;
          color: #000;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
          letter-spacing: 0.25px;
        }
      }

      .bottomNote {
        display: inline-block;
        color: #000;
        font-size: 12px;
        font-weight: 400;
        margin-left: 28px;
        margin-top: -4px;
      }
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1086px;
  width: 100%;
  margin: 0 auto;
  padding: 0px;
  font-family: 'Quicksand';
  font-weight: 500;

  .courseDashboardToBarContainer {
    @media (max-width: 767px) {
      margin-top: 12px;
    }
  }
  @media (max-width: 767px) {
    padding: 0px;
  }
  .navbarCourseDashboard {
    @include respond(mobile) {
      display: none;
    }
  }
  .myPlayListTitle {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-top: 12px;
    margin-bottom: 8px;
    padding: 10px 0px 10px 16px;
    color: #453e86;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    @media (min-width: 540px) {
      display: none;
    }
  }
  .myplaylistCardWrapper {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 16px;
    cursor: pointer;
    height: 586px;
    overflow: scroll;
    overflow-x: hidden;

    @include respond(mobile) {
      height: unset;
    }

    @media (max-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 420px) {
      grid-template-columns: repeat(1, 1fr);
      min-height: 150px !important;
    }
    .boxContainer {
      font-family: 'Quicksand';
      font-style: normal;
      background: #ffffff;
      border-radius: 6px;
      padding: 13px;
      position: relative;
      display: inline-block;
      filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
      cursor: pointer;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      height: 130px;
      &.active {
        border: 0.5px solid #423dcf;
      }
      @media (max-width: 420px) {
        min-height: 140px;
      }
      .playlust {
        font-size: 12px;
        line-height: 15px;
      }

      .playlisthead {
        display: flex;
        justify-content: space-between;
      }

      .playlisttitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        margin-top: 10px;
        color: #14142b;
        height: 4rem;
      }

      .videosCountPlayList {
        font-size: 12px;
        color: #262338;
      }
      .svgImage {
        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 0px;
        border-radius: 0px 0px 8px 8px;
      }
      .BackImg {
        background-image: url('../../../assets/images/course-dashboard/MyPlayListCard.svg');
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 0px 0px 5px 6px;
      }
    }
    .rightContaner {
      border: 1px solid rgba(0, 0, 0, 0.4);
      width: 100%;
    }
  }
  .noResultsContent {
    margin-top: 24px;
    text-align: center;
  }
  .myPlayListSlectedwrapper {
    display: flex;
    border: 1px solid rgba(44, 133, 228, 0.6);
    border-radius: 6px;
    padding: 16px;
    overflow-y: auto;
    height: 586px;
    overflow: scroll;
    overflow-x: hidden;

    @include respond(mobile) {
      height: unset;
    }

    @media only screen and (max-width: 767px) {
      border: none;
    }

    .leftContainer {
      display: flex;
      flex-direction: column;
      // overflow-y: auto;
      padding-right: 35px;

      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    .boxContainer {
      width: 210px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      // height: 110px;
      font-family: 'Quicksand';
      font-style: normal;
      background: #ffffff;
      border-radius: 6px;
      padding: 13px;
      position: relative;
      margin-bottom: 7px;
      display: inline-block;
      padding: 18px 12px 0px 15px;
      cursor: pointer;
      min-height: 130px;

      &.active {
        border: 0.5px solid #423dcf;
      }

      &.active::after {
        content: '♦';
        position: absolute;
        right: 2px;
        font-size: 50px;
        color: #423dcf;
        transform: rotate(90deg);
        transform-origin: center right;
        border-radius: 2px;
        top: calc(35% + 0px);
        z-index: -2;
      }
      .playlust {
        font-size: 12px;
        line-height: 15px;
      }

      .playlisthead {
        display: flex;
        justify-content: space-between;
      }

      .playlisttitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        margin-top: 10px;
        color: #14142b;
        height: 4rem;
      }

      .videosCountPlayList {
        font-size: 12px;
        color: #262338;
      }
      .svgImage {
        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 0px;
        border-radius: 0px 0px 8px 8px;
      }
      .BackImg {
        background-image: url('../../../assets/images/course-dashboard/MyPlayListCard.svg');
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 0px 0px 5px 6px;
      }
    }
    .rightSectionContainerWrapper {
      width: 100%;
      border-radius: 4px;
      padding: 8px 12px;

      @media (max-width: 767px) {
        border: 1px solid rgba(44, 133, 228, 0.6);
      }
    }
    .videosContainerTopBar {
      @media (min-width: 767px) {
        display: none;
      }
    }
    .rightSectionContainer {
      display: grid;
      // grid-template-columns: repeat( 2, minmax(50%, 1fr));
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 1fr);
      gap: 10px;

      @media (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
      }

      @media (max-width: 540px) {
        width: 100%;
      }
    }
    .selectedPlayListTitle {
      color: #000000;
      font-size: 12px;
      margin-top: 12px;
      margin-bottom: 8px;
      display: inline-block;
    }
    .videosCount {
      display: block;
      line-height: 150%;
      color: #000000;
      opacity: 0.3;
      padding-bottom: 2px;
      padding-top: 8px;
    }
    .borderLine {
      border: 1px solid #cccccc;
    }
  }

  .noResultsWrapper {
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    padding: 50px 35px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    height: 586px;

    @include respond(mobile) {
      height: unset;
    }
    .noResultTitle {
      font-size: 20px;
      font-weight: 900;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
    .noResultContent {
      color: #a0a3bd;
      font-size: 16px;
    }
  }

  .courseItemsWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 12px;
    background: #ffffff;

    @include respond(tablet) {
      grid-template-columns: 1fr 1fr;
    }

    @include respond(mobile) {
      grid-template-columns: 1fr;
    }
  }
}

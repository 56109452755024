@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.anchorTag {
  text-decoration: none;
  color: inherit;
}
.wrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  min-height: 94px;
  height: 100%;
  padding: 10px 16px 12px;
  // box-shadow: 0px 0px 2x rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  // transition: box-shadow 300ms;

  &:hover {
    border: 1px solid #423dcf !important;
    border-style: solid !important;
    border-radius: 6px !important;
    box-sizing: border-box !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24) !important;
    cursor: pointer !important;
  }

  &.videoDash {
    box-shadow: unset;
    transition: none;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 13px;
  }

  &.feedTypeWrapper {
    height: 114px;
    padding: 12px;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  &.bookMarkWrapper {
    height: 118px;
    padding: 12px;
    padding-top: 12px;
    padding-bottom: 11px;
  }

  .courseInfo {
    display: flex;
    margin-bottom: auto;

    .feedTimeStamp {
      display: block;
      width: 100%;
      color: #7c7c7c;
      font-family: 'Avenir';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0px;
    }

    .courseTitle {
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin-right: 20px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .feedTitle {
      line-height: 165% !important;
      height: 46px;
    }

    .courseIcon {
      display: inline-block;
      flex: 0 0 24px;
      color: courseDashboardColor('700');
    }
    .feedCourseIcon {
      flex: 0 0 24px;
    }
  }

  .bookMarkInfo {
    height: 40px;
    .courseTitle {
      line-height: unset;
    }
  }
  .feedInfo {
    margin: 0px;
    margin-bottom: 8px;
    height: 16px;
  }

  .courseFooter {
    margin-top: 12px;
    padding-top: 9px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    @media only screen and (max-width: 767px) {
      border-top: 1px dashed rgba(151, 151, 151, 0.5);
    }

    .infoBar {
      display: flex;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.4);

      .courseDuration {
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }
  .freeQuizFooter {
    padding-top: 1px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  .bookMarkFooter {
    padding-top: 14px;
    color: rgba(0, 0, 0, 0.4);
    font-family: 'Avenir';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .courseNameWrapper {
    padding-top: 6px;
    .bookmarkCourseName {
      color: var(--alias-grayscale-label, #6e7191);
      font-family: 'Avenir';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .bookmarkCourseTitle {
      color: #423dcf;
    }
  }
}

.skeleton {
  font-family: $font-family;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  padding: 16px 29px 6px 16px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  transition: box-shadow 300ms;
  cursor: default;

  * {
    border-radius: 2.5px;
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .folderIndex {
      height: 20px;
      width: 80px;
      background: linear-gradient(-45deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
      background-size: 200%;
      animation-name: shimmer;
    }

    .folderTopics {
      height: 12px;
      width: 35px;
      background: linear-gradient(-45deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
      background-size: 200%;
      animation-name: shimmer;
    }
  }

  .content {
    height: 40px;
    width: 90%;
    background: linear-gradient(-45deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
    background-size: 200%;
    animation-name: shimmer;
    margin-bottom: 12px;
  }

  .footer {
    height: 18px;
    width: 75%;
    background: linear-gradient(-45deg, #f6f6f6 35%, #ebebeb 50% 60%, #f6f6f6 75% 100%);
    background-size: 200%;
    animation-name: shimmer;
  }

  @keyframes shimmer {
    0% {
      background-position: 200%;
    }
    100% {
      background-position: 0%;
    }
  }
}

.resultNotAvailableWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  width: 475px;
  padding: 24px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  .closeBtn {
    position: absolute;
    top: 0;
    right: 20px;
  }

  .resultNotAvailableContent {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .image { 
      display: block;
      margin: 0 auto;
      height: 99px;
      width: 106px;
    }

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      text-align: center;

      h6 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: #202126;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #52565E;
      }
    }
  }
}
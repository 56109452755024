@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.filterPopupVisible {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  opacity: 1;
  animation: show 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;

  .filterPopupCard {
    background: #ffff;
    box-shadow: 0rem 0rem 0.8rem 0rem rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding: 24px 20px;
    max-width: 378px;
    width: 100%;

    @include respond(mobile) {
      padding: 20px 12px;
    }

    .headWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      .headingWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        .heading {
          margin-left: 8px;
          color: #000;
          font-family: Avenir;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
        }
      }

      .closeBtn {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .line {
      margin: 15px 0;
      height: 2px;
      background: repeating-linear-gradient(
        to right,
        transparent,
        transparent 10px,
        rgba(0, 0, 0, 0.1) 10px,
        rgba(0, 0, 0, 0.1) 20px
      );
    }

    .parentFilterWrapper {
      @include flex(row, center, flex-start);

      .parentFilter {
        color: #333;
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding: 6px 10px;
        margin: 10px 13px;

        &:hover {
          border-radius: 40px;
          border: 1px solid #ebecfe;
          cursor: pointer;
        }
      }

      .parentFilterActive {
        color: #423dcf;
        border-radius: 40px;
        border: 1px solid #423dcf;
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding: 6px 10px;
        margin: 10px 13px;

        &:hover {
          cursor: pointer;
        }
      }

      .parentFilterDisabled {
        color: #333;
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding: 6px 10px;
        margin: 10px 13px;
        opacity: 0.5;
      }
    }

    .actionBtnsWrapper {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 29px;

      .clearBtn {
        padding: 8px 16px;
        border-radius: 8px;
        border: 2px solid #423dcf;
        color: #423dcf;
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        margin-right: 12px;

        &:hover {
          cursor: pointer;
        }
      }
      .applyBtn {
        padding: 9px 80px;
        border-radius: 8px;
        background: #423dcf;
        border: 2px solid #423dcf;
        color: #fff;
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        margin-right: 12px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.filterPopupHidden {
  display: none;
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.pauseModalWrapper {
    border-radius: 12px;
    background: var(--White, #FFF);
    font-family: "Avenir";
    .titleWrapper {
      display: flex;
      flex-direction: row;
      text-align: center;
      align-items: center;
      margin-bottom: 16px;
      .title {
        display: flex;
        text-align: center;
        color: var(--High-Emphasis, #000);
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 34.317px;
        margin-left: 12px;
      }
    }
    .content {
      .pauseOuterText {
        height: 28px;
        margin-bottom: 12px;
        color: var(--High-Emphasis, #000);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30.027px;
      }
      .pauseInnerText {
        height: 20px;
        margin-bottom: 24px;
        color: var(--Mid-Emphasis, rgba(21, 25, 32, 0.50));
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.598px;
      }
      .pauseButtonWrapper {
        display: flex;
        justify-content: space-between;
        text-align: center;
        button {
          padding: 14px 0px;
          width: 190px;
          border-radius: 12px;
          text-align: center;
          font-weight: 800;
          line-height: 24px;
          letter-spacing: 0.75px;
          font-size: 16px;
          font-style: normal;
          &.keepAttemptButton {
            border: 2px solid var(--alias-grayscale-line, #423DCF);
            background: var(--alias-primary-primary-study-iq-colour, #423DCF);
            color: var(--alias-grayscale-background, #FCFCFC);
          }
          &.pauseTestButton {
            border: 2px solid var(--alias-grayscale-line, #D9DBE9);
            color: var(--alias-primary-primary-study-iq-colour, var(--StudyIQ-Primary, #423DCF));
          }
        }
      }
    }
  }
  
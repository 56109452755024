@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.ratingModalBody{
    padding-top: 20px !important;
    
    .ratingModalMainClass {
        border-radius: 12px !important;
        width: 528px !important;
        padding: 24px 64px 24px 64px !important;
        font-feature-settings: 'clig' off, 'liga' off;
        .ratingModal_wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .queQuality,.interfaceQuality{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .heading{
                    color: #000;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 32px; /* 160% */
                    text-align: center;
                }
            }
            .queQuality{
                margin: 0px 0px 20px 0px;
            }
            .image_wrapper{
                margin-bottom: 20px;
            }
            .interfaceQuality{
                margin-bottom: 40px;
            }
            .btnWrapper{
                .submitBtn{
                    border-radius: 8px;
                    background:  #423DCF;
                    font-size: 17px;
                    font-weight: 800;
                    color: white;
                    width: 200px;
                    text-align: center;
                    padding: 9px 0px;
                    cursor: pointer;
                }
                .submitBtn_dis{
                    cursor: not-allowed;
                }
            }
            .suggetionInput{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 19px;
                width: 92%;
                .heading{
                    color: #6E7191;
                    font-size: 14px;
                    font-weight: 400;
                    // line-height: 32px; /* 228.571% */
                    margin-bottom: 8px;
                }
                .textErrorMsg{
                    color:#CA024F;
                    white-space: nowrap;
                }
                .inputText{
                    border-radius: 7px;
                    border: 0.5px solid #D8D8D8;
                    // width: 400px;
                    width: 100%;
                    height: 48px;
                    padding-left: 15px;
                    padding-right: 15px;
                    // padding: 17px 15px;
                    font-size: 14px;
                }
                .inputText_error{
                    border: 0.5px solid #CA024F;
                }
            }
          }
      }
}
.ratingStarWrapper{
    display: flex;
    flex-direction: row;
    gap: 16px;
    .star{
        cursor: pointer;
    }
}

.thanksRatingModalMainClass {
    border-radius: 12px !important;
    width: 528px !important;
    padding: 24px 64px 24px 64px !important;;
    font-feature-settings: 'clig' off, 'liga' off;
    top:15%;
    .content{
        display: flex;
        flex-direction: column;
    }
    .likeSuccessBtn {
        text-align: center;
      }

      .thanksText {
        // font-family: $font-family_1;
        font-style: normal;
        font-weight: 900;
        font-size: 2rem;
        color: #14142b;;
        letter-spacing: 0.025rem;
        text-align: center;
        // margin-top: 16px;
      }
}
  
@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  background: #ffffff;
  box-shadow: 0 0 20px 4px rgba(214, 214, 214, 0.6);
  border-radius: 8px;
  padding: 16px 12px 16px 8px;

  .menu {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 12px;

    .menuItem {
      cursor: pointer;
      width: 100%;

      .menuItemLink {
        position: relative;
        display: flex;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        padding: 8px 12px;
        color: #6e7191;
        text-decoration: none;
        border-radius: 4px;
        transition: all 0.3s;

        &:hover {
          color: #262338;
        }
      }

      &.isNew .menuItemLink::after {
        content: 'New';
        position: relative;
        top: -4px;
        left: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 18px;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0.5px;
        border-radius: 12px;
        background: #F13838;
        color: #FFFFFF;
        text-transform: uppercase;
      }

      &.active .menuItemLink {
        background: #f7f7fc;
        color: #423dcf;
      }
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  align-items: center;
  padding: 0;

  .contentWrapper {
    width: 550px;
    border-radius: 8px;
    padding: 0;

    .headerWrapper {
      display: flex;
      justify-content: center;
      padding: 12px 0 8px 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.87);
      border: 1px solid rgba(0, 0, 0, 0.05);

      > button {
        top: 13px !important;
        right: 20px !important;
      }
    }

    .banner {
      display: flex;
      padding: 9px 20px;
      background: #f2f0ff;

      .bannerIcon {
        font-size: 40px;
      }

      .bannerContent {
        display: flex;
        flex-direction: column;
        margin-left: 17px;

        .bannerContentTitle {
          font-weight: 500;
          font-size: 17px;
          line-height: 23px;
          display: flex;
          align-items: center;
          color: #423dcf;
        }

        .bannerContentSubtitle {
          font-weight: 400;
          font-size: 13px;
          line-height: 22px;
          letter-spacing: -0.408px;
          color: #7a7575;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 15px 0px;
      padding: 16px 20px;

      .questionWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        cursor: text;

        .question {
          display: inline-block;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #000000;
          margin-bottom: 14px;
        }

        .answersWrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 12px 0;

          .answerWrapper {
            display: flex;
            align-items: center;
            cursor: text;

            .answerLabel {
              position: relative;
              display: inline-block;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              cursor: pointer;
            }

            input[type='radio'],
            input[type='checkbox'] {
              + .answerLabel {
                margin-left: 25px;
              }

              + .answerLabel::before,
              + .answerLabel::after {
                top: 0%;
                left: -24px;
              }

              + .answerLabel::before {
                content: '';
                position: absolute;
                height: 18.5px;
                width: 18.5px;
                border: 2px solid #bfbefc;
                border-radius: 50%;
              }

              + .answerLabel::after {
                content: '';
                position: absolute;
                left: -19.75px;
                top: 4.25px;
                height: 10px;
                width: 10px;
                background-color: transparent;
                border-radius: 50%;
              }

              &:checked + .answerLabel::after {
                background-color: #423dcf;
              }
            }

            input[type='checkbox'] + .answerLabel::after,
            input[type='checkbox'] + .answerLabel::before {
              border-radius: 2px;
            }

            input:disabled {
              cursor: not-allowed;
            }

            input:disabled:checked + .answerLabel::after {
              background-color: gray;
            }

            input:disabled + .answerLabel::before {
              border-color: lightgray;
            }
          }
        }
      }

      .submitButton {
        display: block;
        color: #fcfcfc;
        padding: 9px 15px;
        background-color: #423dcf;
        font-weight: 900;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.25px;
        border-radius: 8px;
        text-align: center;
        margin-top: 25px;
      }
    }
  }
}

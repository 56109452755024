@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: #787a80;
  white-space: nowrap;

  .iconWrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    font-size: 16px;
  }

  &.active .iconWrapper {
    border: 2px solid transparent;
  }

  &.smallSize {
    font-size: 14px;

    .iconWrapper {
      height: 24px;
      width: 24px;
      font-size: 10px;
    }
  }

  &.largeSize {
    font-size: 18px;

    .iconWrapper {
      height: 46px;
      width: 46px;
      font-size: 18px;
    }
  }

  &.answered .iconWrapper {
    background-color: #008a00;
    color: #ffffff;
    border-color: darken(#008a00, 10%);
  }

  &.notVisited .iconWrapper {
    background-color: rgba(0, 0, 0, 0.2);
    color: #ffffff;
    border-color: darken(rgba(0, 0, 0, 0.2), 10%);
  }

  &.notAnswered .iconWrapper {
    background-color: #a996ff;
    color: #ffffff;
    border-color: darken(#a996ff, 10%);
  }

  &.markedForReview .iconWrapper {
    background-color: #0041ac;
    color: #ffffff;
    border-color: darken(#0041ac, 10%);
  }

  &.markedForReviewAndAnswered .iconWrapper {
    background-color: #4e4b66;
    color: #ffffff;
    border-color: darken(#4e4b66, 10%);
  }

  &.wrongAnswered .iconWrapper {
    background-color: #E61B00;
    color: #ffffff;
    border-color: darken(#E61B00, 10%);
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.similarCourses_wrapper{
  // position: relative;
  width: 100%;
  &.similarCourses_wrapper_successPage{
    width: 90%;
    margin-top: 25px;
  }
  .similarCoursesSectionHeading {
    height: 64px;
    padding: 18px;
    background: linear-gradient(90deg, rgba(0, 159, 255, 0.19) 0%, rgba(255, 255, 255, 0.195282) 100%);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 11px;
    margin-bottom: 25px;

    h3 {
      font-size: 20px;
      font-weight: bold;
      @include respond(mobile){
        font-size: 15px;
      }
    }
    @include respond(mobile){
      background:white;
      height: auto;
      box-shadow: none;
      padding: 18px 0px;
      margin-bottom: -10px;

    }

  }
  .sucessPageOffset{
    // margin: 20px 50px 25px 50px;
  }
  .web_slider{
    position: relative;
    display: block;
    @include respond(mobile){
      display: none;
    }
  }
  // :global(.swiper) {
  //    width: 84vw;
  // }
  .mwebList{
    display: none;
    @include respond(mobile){
      display: block;
    }
  }
  .similarCourses_swiper{
    margin: 0px 50px !important; 
  }
  .swiper_purchase_success{
    margin: 0px 50px !important; 
  }
  .similarCourseSliderNavigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    z-index: 5;
    transform: translateY(-55%);
    padding: 0px 8px;

    // @include respond(tablet){
    //   display: none;
    // }

    button:disabled,
    button[disabled]{
      visibility: show;
    }
    .similarCourseSliderNavLeft,
    .similarCourseSliderNavRight {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      background-color: #ffffff;
      border: 1px solid #DFDFDF;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      color: #DFDFDF;
      border-radius: 50%;
      font-size: 13px;

      &:not(:global(.swiper-button-disabled)) {
        background: #423DCf;
        color: #fff;
        border-color: rgb(66, 61, 207);
        font-size: 13px;
      }
    }
  }
  .wrapper {
    .course {
      display: flex;
      flex-direction: column;
      border: 1px solid #d3d3d3;
      border-radius: 20px;
      padding: 10px;
      max-width: 300px;
  
      .courseThumbnailWrapper {
        position: relative;
        width: 100%;
        max-width: 285px;
        overflow: hidden;
        border-radius: 10px;
        aspect-ratio: 16/10;
      }
  
      .courseContent {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid rgba(0,0,0,.1);
        padding-bottom: 5px;
        margin-bottom: 10px;
  
        .courseTitle {
          display: -webkit-box;
          font-size: 18px;
          margin-top: 5px;
          font-weight: bold;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 58px;
        }
  
        .coursePriceWrapper {
          display: flex;
          gap: 5px;
  
          .courseNetPrice {
            color: #999;
            margin-right: 5px;
            text-decoration: line-through;
            font-size: 13px;
          }
  
          .courseSalePrice {
            color: #423dcf;
            font-size: 15px;
            font-weight: 800;
          }
        }
  
        .courseOfferWrapper {
          display: flex;
          font-size: 13px;
          padding: 2px 0;
          text-align: left;
          margin-bottom: 0;
          font-weight: 500;
          align-items: center;
          gap: 5px;
        }
      }
  
      .actionButton {
        width: 100%;
        display: block;
        text-decoration: none;
        background-color: #423dcf;
        border-radius: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        padding: 8px 1px;
        text-align: center;
      }
    }
  }
  .crossSell_main_mweb {
    text-align: left;
  
    .line_clamp {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  
    .container {
      .media {
        border-bottom: 1px dashed lightgray;
        margin-top: 8px;
      }
  
      .heading {
        font-size: 15px;
        font-weight: bold;
      }
  
      .imageMweb {
        width: 100%;
        max-height: 100px;
        border-radius: 10px;
      }
  
      .heading_title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0px;
      }
  
      .item_price {
        margin-bottom: 0px;
      }
  
      .item_price strike {
        color: #999;
        margin-right: 5px;
        font-size: 13px;
      }
  
      .item_price span {
        color: #007aff;;
        font-size: 15px;
        font-weight: 800;
      }
  
      .offers_p {
        .offer_text{
          margin-left: 5px;
        }
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 2px;
      }
  
      .view_offer_btn {
        background-color: #007aff;;
        width: 55%;
        border-radius: 8px;
        border: none;
        font-size: 15px;
        font-weight: bold;
        color: white;
        padding: 5px 0px;
        margin-top: 2px;
        text-align: center;
      }
  
      .hr {
        // margin-top: 75px !important;
        border-top: 1px dashed lightgrey;
      }
      .media{
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 15px;
      }
          .img_fluid {
            width: 80px;
            /* height: 72px; */
            max-height: 80px;
            float: left;
          }
  
          .thumb_content {
            margin-left: 10px;
            float: left;
  
            .offers_p {
              font-size: 13px;
              padding: 2px 0;
              text-align: left;
              margin-bottom: 0px;
              font-weight: 500;
              display: flex;
              .offer_text{
                margin-bottom: 5px;
              }
              .icon_img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                // margin-bottom: 4px;
                padding-right: 5px;
              }
            }
  
            h4 {
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 0px;
            }
  
            .item_price {
              margin-bottom: 0px;
            }
  
            .item_price strike {
              color: #999;
              margin-right: 5px;
              font-size: 13px;
            }
  
            .item_price span {
              color: #007aff;;
              font-size: 15px;
              font-weight: 800;
            }
  
            .view_offer_btn {
              background-color: #007aff;;
              width: 55%;
              border-radius: 8px;
              border: none;
              font-size: 15px;
              font-weight: bold;
              color: white;
              padding: 5px 0px;
              margin-top: 2px;
              text-align: center;
            }
  
            .hr {
              // margin-top: 75px !important;
              border-top: 1px dashed lightgrey;
            }
  
          }
    }
  
  }
  
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
$color_1: #14142b;
$color_2: #fcfcfc;
$color_3: #423dcf;
$color_4: #a996ff;
$color_5: #ebecfe;
$color_6: #eeeefb;
$color_7: #d9d9d9;
$color_8: #f7f7fc;
$color_9: #6e7191;
$color_10: rgba(202, 2, 79, 1);
$font-family_1: 'Avenir';

.container {
  @include fwcontainer;
  padding: 24px 0px 0px 0px;

  @include respond(mobile) {
    padding-top: 0px;
  }

  // Instructions Screen
  .headingWrapper {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 10px 1;
    margin-bottom: 12px;
    .heading {
      color: var(--alias-grayscale-header, #14142b);
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: 120%;
      padding: 12px;
    }
    .textSkelton {
      @include skeleton(10%, 1.7rem);
    }

    @include respond(mobile) {
      display: none;
    }
  }
  .questionContainerWrapper {
    height: 70vh;

    &.containerWrapper {
      height: unset;
    }

    @include respond(mobile) {
      height: 100vh;
      padding: 12px 16px 0px 12px;
    }

    .wrapper {
      height: 60vh;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      @include respond(mobile) {
        height: 90vh;
        // padding: 12px 16px 0px 12px;
      }
    }
  }
  .questionWrapper {
    padding: 12px 0px 0px 0px;
    cursor: text;

    @include respond(mobile) {
      &.questionWrapperSubmitScrn {
        padding: 12px 16px 0px 16px;
      }
    }

    .textSkelton {
      @include skeleton(100%, 4rem);
    }

    @include respond(mobile) {
      padding: 12px 0px 0px 0px;
    }
    .question {
      margin-left: 5px;
      span {
        margin-right: 5px;
        color: $color_1;
        font-size: 14px;
        font-family: Avenir;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
      }
      .instructionContent {
        display: inline-block;
      }
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      color: $color_1;
      line-height: 20px;
    }
  }
  .instructionsWrapper {
    margin: 20px 5px 0px 5px;

    &.instructionsDisplay {
      display: block;
    }
    @include respond(mobile) {
      // margin: 10px 0px;
      margin: 10px 5px 0px 5px;
      display: none;
    }
    .instructionContent {
      display: inline-block;
    }
    .instructions {
      span {
        color: #14142b;
        font-size: 14px;
        font-family: Avenir;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        margin-right: 5px;
      }
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      color: $color_1;

      .hideInstructions {
        font-family: $font-family_1;
        font-size: 1.4rem;
        font-weight: 900;
        color: $color_3;
        margin-left: 5px;

        &:hover {
          cursor: pointer;
        }

        @include respond(mobile) {
          display: none;
        }
      }
    }
  }
  .markingSchemeWrapper {
    margin: 2rem 0rem;
    color: #14142b;
    font-size: 14px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    line-height: 150%;
    .markingScheme {
      span {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 900;
        font-size: 1.4rem;
        color: $color_1;
      }
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      color: $color_1;
    }
  }
  .startExamBtnWrapper {
    padding: 28px 0px 0px 0px;
    position: sticky;
    bottom: 4rem;
    background: #ffffff;

    @include respond(mobile) {
      margin: 0px;
    }
    .startExamBtn {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .btn {
        cursor: pointer;
        background: $color_3;
        border-radius: 0.8rem;
        padding: 9.5px 36px;
        color: $color_2;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        text-align: center;
      }
    }
  }
  @include respond(mobile) {
    .startExamBtnWrapper {
      position: sticky;
      bottom: 0rem;
      background: #ffffff;
      box-shadow: 0rem -0.2rem 0.8rem rgba(0, 0, 0, 0.1);
    }
  }

  .commentWrapperMobile {
    @include respond(mobile) {
      margin-top: 2rem;
      margin-bottom: 10rem;
      row-gap: 8px;
      display: flex;
      flex-direction: column;
    }
    .headingComment {
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 900;
      font-size: 1.6rem;
      color: #14142b;
      margin: 12px 0px;
    }
  }
  .commentRemarksWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    // margin-top: 18px;

    @include respond(mobile) {
    }

    .comentsRemarksTxt {
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      color: #6e7191;

      span {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 900;
        font-size: 1.6rem;
        color: #14142b;
      }
    }
  }

  // Start Exam Screen

  .viewInstructions {
    margin-left: 5px;
    font-family: $font-family_1;
    font-size: 16px;
    font-weight: 900;
    color: $color_3;
    text-decoration: underline;
    line-height: 20px;
    // margin-bottom: 12px;

    &:hover {
      cursor: pointer;
    }

    @include respond(mobile) {
      display: none;
    }
  }

  .textInput {
    width: 100%;
    height: 34.5rem;
    margin-top: 20px;
    border-radius: 6px;
    border: 1px solid #d8d8d8 !important;
    background: rgba(255, 255, 255, 0.6);
    padding: 12px;
    color: #090023;
    font-size: 14px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    @include respond(mobile) {
      height: 54.5rem;
    }
    &:focus {
      outline: none;
      border: 1px solid #423dcf !important;
    }
  }

  .uploadMobileWrapper {
    @include flex(row, center, space-between);
    .textLength {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      color: $color_3;

      span {
        color: $color_1;
      }
    }
    .uploadedFilesMobile {
      display: none;
      svg {
        margin-right: 5px;
      }
      @include respond(mobile) {
        position: relative;
        margin: 0 2rem;
        @include flex(row, center, space-between);
        align-content: center;
        .clipIconMobile {
        }
        .filesTextMobile {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          color: #6e7191;
          line-height: 22px;
          letter-spacing: 0.25px;
        }

        .removeIconMobile {
          margin: 0 1.1rem;
        }

        &:hover {
          cursor: pointer;
        }

        .filesWrapperMobile {
          max-height: 30rem;
          overflow: scroll;
          position: absolute;
          bottom: 3rem;
          right: 0rem;
          width: 31.1rem;
          background: #ffffff;
          border: 0.1rem solid #d9dbe9;
          border-radius: 0.6rem;

          .filesMobile {
            @include flex(row, center, space-between);
            border-bottom: 1px solid #d9dbe9;
            .filesNameMobile {
              padding: 1.2rem;
              font-family: $font-family_1;
              font-style: normal;
              font-weight: 400;
              font-size: 1.2rem;
              color: #6e7191;
            }
          }
        }
      }
    }
  }

  .btnWrapper {
    @include flex(row, center, space-between);
    margin: 26px 0px;

    @include respond(mobile) {
      margin: 0px;
    }
    .uploadWrapper {
      svg {
        margin-right: 5px;
      }
      @include flex(row, center, space-evenly);
      .uploadBtn {
        border: 0.1rem solid $color_3;
        border-radius: 0.8rem;
        padding: 8.5px 16px;
        color: $color_3;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 900;
        font-size: 1.4rem;
        text-align: center;

        span {
          margin-right: 5px;
        }
      }

      .uploadedFiles {
        position: relative;
        padding: 0.4rem 1.1rem;
        margin: 0 2rem;
        @include flex(row, center, space-between);
        align-content: center;
        background: $color_5;
        border-radius: 0.6rem;

        .filesText {
          color: var(--alias-primary-primary-study-iq-colour, #423dcf);
          text-align: center;
          font-size: 13px;
          font-family: Avenir;
          font-style: normal;
          font-weight: 900;
          line-height: 22px;
          letter-spacing: 0.25px;
          text-decoration-line: underline;
          margin-left: 2px;
        }

        .removeIcon {
          margin: 0 1.1rem;
        }

        &:hover {
          cursor: pointer;
        }

        .filesWrapper {
          max-height: 30rem;
          overflow: scroll;
          position: absolute;
          bottom: 3rem;
          left: 9.1rem;
          width: 40.6rem;
          background: #ffffff;
          border: 0.1rem solid #d9dbe9;
          border-radius: 0.6rem;

          .files {
            @include flex(row, center, space-between);
            border-bottom: 1px solid #d9dbe9;
            .filesName {
              padding: 1.2rem;
              font-family: $font-family_1;
              font-style: normal;
              font-weight: 400;
              font-size: 1.2rem;
              color: #6e7191;
            }
          }
        }
      }
      @include respond(mobile) {
        .uploadedFiles {
          display: none;
        }
      }
    }
    .downloadBookletBtn{
      // width: 186px;
      background: $color_3;
      border-radius: 0.8rem;
      padding: 7px 22px;
      color: $color_2;
      font-family: $font-family_1;
      font-weight: 900;
      font-size: 13px;
      text-align: center;
      margin-left: auto;
      margin-right: 12px;
      .content{
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: center;
        .text{
          margin-top: 6px;
        }
      }
    }
    .submitBtn {
      background: $color_3;
      border-radius: 0.8rem;
      padding: 10.2px 16px;
      color: $color_2;
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 900;
      font-size: 13px;
      text-align: center;
    }

    .submitBtnDisabled {
      background: #a996ff !important;
      cursor: default !important;
    }
  }
  @include respond(mobile) {
    .btnWrapper {
      position: sticky;
      bottom: 0rem;
      background: #ffffff;
      box-shadow: 0rem -0.2rem 0.8rem rgba(0, 0, 0, 0.1);
      padding: 0.8rem 1.6rem;
      //Uncomment if necessary
      //   z-index: 100000000000 !important;
    }
  }

  // Mobile header screen

  .mobileHeaderWrapper {
    display: none;

    @include respond(mobile) {
      background: #ffffff;
      box-shadow: 0rem 0rem 0.4rem rgba(9, 0, 35, 0.5);
      @include flex(row, center, space-between);
      padding: 16px;
      // margin: -0.5rem -0.5rem 1.2rem -0.5rem;

      .leftSection {
        @include flex(row, center, space-between);

        .backIcon {
          margin-right: 18px;
          cursor: pointer;
        }

        .headText {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: $color_1;
        }
      }
      div {
        svg {
          cursor: pointer;
        }
      }
    }
  }

  .statusWrapper {
    display: flex;
    column-gap: 120px;
    /* flex-wrap: wrap; */
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #eeeefb;
    border-radius: 0.4rem;
    margin-bottom: 2px;

    @include respond(ipad) {
      flex-wrap: wrap;
    }

    .statusInfo {
      margin: 1.2rem;
      display: flex;
      /* flex-wrap: wrap; */
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @include respond(mobile) {
        width: 100%;
        margin: 10px 16px 8px 16px;
      }

      .status {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 900;
        font-size: 1.6rem;
        color: $color_1;

        .textSkelton {
          @include skeleton(100px, 1.7rem);
        }

        // .textSkelton {
        //   animation: skeleton-loading 1s linear infinite alternate;
        //   width: 100px;
        //   display: inline;
        //   height: 1.7rem;
        //   border-radius: 0.25rem;
        //   display: inline-block;
        // }

        // @keyframes skeleton-loading {
        //   0% {
        //     background-color: hsl(200, 20%, 80%);
        //   }
        //   100% {
        //     background-color: hsl(200, 20%, 95%);
        //   }
        // }

        span {
          color: $color_3;
          font-weight: 500;
          margin-left: 3px;

          &.evaluated {
            color: #008a00;
          }
          &.submitted {
            color: $color_3;
          }
        }
      }
    }

    .reviewInfo {
      /* width: 45%; */
      display: flex;
      /* flex-wrap: wrap; */
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 1.2rem;
      width: 100%;
      margin: 1.2rem;
      @include respond(mobile) {
        width: 100%;
        margin: 10px 16px 8px 16px;
      }

      .review {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 900;
        font-size: 1.6rem;
        color: $color_1;

        .textSkelton {
          @include skeleton(100px, 1.7rem);
        }

        span {
          color: $color_3;
          font-weight: 500;
          margin-left: 3px;
        }
      }
    }
  }

  .submitionWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 16px;
    display: grid;
    grid-template-columns: 248px minmax(0, 1fr);
    margin-top: 20px;

    @include respond(mobile) {
      display: none;
    }

    @include respond(tablet) {
      grid-template-columns: 170px minmax(0, 1fr);
    }

    .leftMenu {
      // width: 20%;
      background: #fff;
      box-shadow: 0rem 0rem 2rem 0.4rem rgba(214, 214, 214, 0.6);
      border-radius: 1.2rem;
      padding: 16px 10px;
      // width: 248px;

      .textSkelton {
        @include skeleton(100px, 1.7rem);
      }

      .menuActive {
        background: $color_8;
        border-radius: 0.4rem;
        padding: 11px 10px;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 800;
        font-size: 1.4rem;
        color: $color_3;
        // margin: 1rem 0rem;

        &:hover {
          cursor: pointer;
        }
      }
      .submissionToggle {
        display: flex;
        justify-content: space-between;
        span {
          color: black;
        }
      }

      .submissionToggle {
        &span {
          color: black;
        }
      }

      .menu {
        border-radius: 0.4rem;
        padding: 11px 10px;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        color: var(--alias-grayscale-label, #6e7191);
        // margin: 1rem 0rem;

        &:hover {
          color: var(--alias-grayscale-header-weak, #262338);
          cursor: pointer;
        }
      }
      .disabled {
        opacity: 0.4;
        pointer-events: none;
      }
      .disabled_noneDisplay {
        display: none;
      }

      .submissionList {
        // padding: 1rem;
        margin: 20px 0px;

        ul {
          gap: 20px;
          display: flex;
          flex-direction: column;
        }

        .activeMenuList {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 800;
          font-size: 1.4rem;
          color: $color_3;
          // margin-top: 16px;

          &:hover {
            cursor: pointer;
          }
        }

        .menuList {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          color: $color_9;
          // margin: 0.8rem 0rem;

          &:hover {
            color: var(--alias-grayscale-header-weak, #262338);
            cursor: pointer;
          }
        }
      }

      @include respond(mobile) {
        display: none;
      }
    }
    .pdfContainer {
      max-width: 100%;
      overflow-y: auto;
      height: 44rem;
      border-radius: 8px;
      border: 1px solid #000;
    }

    .pdfPage {
      display: block;
      margin: 10px auto;
    }
    .pdfViewerEmpty {
      background: $color_7;
    }
    .rightSection {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
    }
    .pdfViewer {
      // width: 75%;
      height: 44rem;
      border-radius: 0.8rem;
      position: relative;
      @include flex(row, center, center);
      @include respond(mobile) {
        display: none;
      }

      .demoText {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 900;
        font-size: 6.3rem;
        color: #000;
        width: 100%;
        height: 100%;
      }

      .downloadIcon {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }
  .feedbackAdvise{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 4px;
    @include respond(mobile) {
      display: none;
    }
    .text_main{
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      background:  #FFF8E9;
      border-radius: 4px;
      padding: 10px 16px;
      .image_main{
        margin:0px 8px 0px 0px;
      }
      .text{
        color: #000;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 2px
      }
    }
  }

  .feedbackMenu {
    @include flex(row, center, space-between);
    margin-top: 0.2rem;
    margin-bottom: 5.2rem;
    .feedback {
      @include flex(flex, center, flex-start);
      padding: 0.9rem;

      &:hover {
        cursor: pointer;
      }

      .feedbackIcon {
        width: 2.4rem;
        height: 2.4rem;
      }

      .feedbackText {
        margin-left: 0.8rem;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 900;
        font-size: 1.4rem;
        color: $color_1;
        text-decoration: underline;
      }
    }
    .reattemptBtn_wrapper{
      display: flex;
      flex-direction: row;
      gap:12px;
      .denybtn{
        display: flex;
        color:  #fcfcfc;
        text-align: center;
        font-family: Avenir;
        font-size: 13px;
        font-weight: 900;
        border-radius: 8px;
        background: $color_10;
        padding: 9px 16px;

      }
    }
    .reattemptBtn {
      cursor: pointer;
      .btn {
        display: flex;
        background: $color_3;
        color: var(--alias-grayscale-background, #fcfcfc);
        text-align: center;

        /* Desktop/Link X-Small */
        font-family: Avenir;
        font-size: 13px;
        font-style: normal;
        font-weight: 900;
        line-height: 22px;
        letter-spacing: 0.25px;
        border-radius: 8px;
        background: var(--alias-primary-primary-study-iq-colour, #423dcf);
        padding: 9px 16px;
      }
    }

    @include respond(mobile) {
      display: none;
    }
  }
  .feedbackMenuobile_wrapper{
    display: none;
    @include respond(mobile) {
      display: block;
      background: #fff;
      box-shadow: 0rem -0.2rem 0.8rem rgba(0, 0, 0, 0.1);
      padding: 0rem 0rem 1.6rem 0rem;
      position: fixed;
      bottom: 0rem;
      margin-top: 9.3rem;
      width: 100%;
      z-index: 10000;
      .feedbackAdviseMobile{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // margin-top: 8px;
        margin-bottom: 4px;
        .text_main{
          // margin-left: auto;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          background:  #FFF8E9;
          border-radius: 4px;
          padding: 8px 16px 5px 16px;
          margin-bottom:4px;
          .image_main{
            margin:0px 8px 0px 0px;
          }
          .text{
            color: #000;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 5px
          }
        }
      }
    }
  }
  .feedbackMenuobile {
    display: none;

    @include respond(mobile) {
      padding: 0rem 1.6rem;
      @include flex(row, center, space-between);
      .feedbackSectionMobile {
        cursor: pointer;
        @include flex(row, center, flex-start);

        .feedbackIconMobile {
          width: 2.4rem;
          height: 2.4rem;
        }

        .feedbackTextMobile {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 900;
          font-size: 1.3rem;
          letter-spacing: 0.025rem;
          color: $color_1;
          margin-left: 0.6rem;
          text-decoration: underline;
        }
      }
      .reattemptBtn_wrapperMobile{
        display: flex;
        flex-direction: row;
        gap:8px;
        .denybtn{
          display: flex;
          color:  #fcfcfc;
          text-align: center;
          font-family: Avenir;
          font-size: 13px;
          font-weight: 900;
          border-radius: 8px;
          background: $color_10;
          padding: 9px 16px;
  
        }
      }
      .reattemptBtnMobile {
        cursor: pointer;

        .btn {
          // width: 14rem;
          // height: 4rem;
          background: $color_3;
          border-radius: 0.8rem;
          padding: 9px 16px;
          color: $color_2;
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          text-align: center;
        }
      }
    }
  }

  .submitionMenuMobile {
    display: none;

    @include respond(mobile) {
      display: block;
      padding: 12px 16px 28px 16px;

      .menuMobile {
        cursor: pointer;
        @include flex(row, center, space-between);
        background: #fff;
        border: 0.1rem solid rgba(0, 0, 0, 0.06);
        box-shadow: 0rem 0rem 0.8rem rgba(0, 0, 0, 0.06);
        border-radius: 0.6rem;
        padding: 1.2rem;
        margin: 0.6rem 0rem;

        .leftMenuMobile {
          @include flex(row, center, flex-start);

          .icon {
            width: 3rem;
            height: 3rem;
          }

          .name {
            margin-left: 0.8rem;
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            color: #000;
          }
        }
      }
    }
  }

  .feedbackPopupVisible {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    opacity: 1;
    animation: show 0.5s ease;
    overflow-x: hidden;
    overflow-y: auto;

    .feedbackPopupCard {
      background: #ffff;
      box-shadow: 0rem 0rem 0.8rem 0rem rgba(0, 0, 0, 0.12);
      // margin: 1rem;
      border-radius: 10px;
      // width: 52.8rem;
      // height: 25.4rem;
      // left: calc(50% - 52.8rem / 2);
      // top: calc(50% - 22.2rem / 2);
      padding: 24px 20px 24px 20px;
      position: relative;
      max-width: 528px;
      width: 100%;

      @include respond(mobile) {
        padding: 20px 12px;
      }

      .closeBtn {
        position: absolute;
        // right: calc(50% - 51.2rem / 2);
        // top: calc(50% - 27.2rem / 2);
        right: 16px;
        top: 16px;

        &:hover {
          cursor: pointer;
        }

        @include respond(mobile) {
          // right: calc(50% - 16.6rem);
          // top: calc(50% - 15.3rem);
        }
      }

      .feedbackIcon {
        text-align: center;
        // margin-top: 2.4rem;
      }
      .headText {
        text-align: center;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 900;
        font-size: 2rem;
        color: $color_1;
        margin-top: 1.6rem;
      }

      .captionText {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        color: $color_9;
        text-align: center;
      }

      .feedbackBtns {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1.6rem;

        .suggetionInput{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 19px;
          margin-top: 16px;
          width: 75%;
          font-family: $font-family_1;
          .heading{
              color: #6E7191;
              font-size: 14px;
              font-weight: 400;
              // line-height: 32px; /* 228.571% */
              margin-bottom: 8px;
          }
          .textErrorMsg{
            color:#CA024F;
            white-space: nowrap;
           }
          .inputText{
              border-radius: 7px;
              border: 0.5px solid #D8D8D8;
              // width: 400px;
              width: 100%;
              height: 48px;
              padding-left: 15px;
              padding-right: 15px;
              // padding: 17px 15px;
              font-size: 14px;
          }
          .inputText_error{
              border: 0.5px solid #CA024F;
          }
        }
        .btnWrapper{
          margin: 0px;
          .submitBtn{
              border-radius: 8px;
              background:  #423DCF;
              font-size: 17px;
              font-weight: 800;
              color: white;
              width: 200px;
              text-align: center;
              padding: 9px 0px;
              cursor: pointer;
          }
          .submitBtn_dis{
              cursor: not-allowed;
          }
        }

        .likeBtn {
          &:hover {
            cursor: pointer;
          }
        }

        .disLikeBtn {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .likeSuccessBtn {
        text-align: center;
      }

      .thanksText {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 900;
        font-size: 2rem;
        color: $color_1;
        letter-spacing: 0.025rem;
        text-align: center;
        // margin-top: 16px;
      }

      .failureHeading {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 900;
        font-size: 2rem;
        color: $color_1;
        letter-spacing: 0.025rem;
        text-align: center;
        // margin-top: 2.4rem;
      }

      .feedbackTextArea {
        border-radius: 10px;
        background: #f6f6f6;
        // height: 16.5rem;
        // width: 48.8rem;
        margin: 1.2rem 0rem;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        padding: 1.2rem;
        width: 100%;
        height: 165px;

        &:focus {
          outline: none;
          border: 1px solid #423dcf !important;
        }

        @include respond(mobile) {
          // width: 30.4rem;
        }
      }

      .sendBtn {
        @include flex(row, center, center);

        .btn {
          width: 14rem;
          background: $color_3;
          border-radius: 0.8rem;
          padding: 0.9rem 1.6rem;
          color: $color_2;
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          text-align: center;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .feedbackPopupHidden {
    display: none;
  }

  .instructionsPopupVisible {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    opacity: 1;
    animation: show 0.5s ease;
    overflow-x: hidden;
    overflow-y: auto;

    .instructionsPopupCard {
      position: relative;
      background: #ffff;
      box-shadow: 0rem 0rem 0.8rem 0rem rgba(0, 0, 0, 0.12);
      margin: 1rem;
      border-radius: 10px;
      max-width: 52.8rem;
      height: 44rem;
      width: 100%;
      overflow-y: scroll;

      .instructionCloseBtn {
        position: absolute;
        right: 18px;
        top: 18px;
        z-index: 2;

        &:hover {
          cursor: pointer;
        }

        // @include respond(mobile) {
        //   right: calc(50% - 16.6rem);
        //   top: calc(50% - 20.5rem);
        //   z-index: 2;
        // }
      }

      .instructionsHeadingWrapper {
        border-bottom: 2px solid $color_7;
        padding: 1.5rem;
        position: sticky;
        top: 0;
        background: #fff;

        .instructionsHeading {
          font-family: $font-family_1;
          font-style: normal;
          font-size: 1.8rem;
          font-weight: 500;
          color: $color_1;
        }
      }

      .instructionsContent {
        margin: 12px;
        padding: 0.5rem;
        font-family: $font-family_1;
        color: $color_1;
        font-style: normal;
        font-size: 1.4rem;
        font-weight: 500;

        ul {
          padding: 0.5rem 2rem;
        }
      }
    }
  }

  .instructionsPopupHidden {
    display: none;
  }

  .attemptsPopupVisible {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    opacity: 1;
    animation: show 0.5s ease;
    overflow-x: hidden;
    overflow-y: auto;

    .attemptsPopupCard {
      position: relative;
      background: #ffff;
      box-shadow: 0rem 0rem 0.8rem 0rem rgba(0, 0, 0, 0.12);
      margin: 1rem;
      border-radius: 10px;
      max-width: 52.8rem;
      // height: 25.4rem;
      // left: calc(50% - 52.8rem / 2);
      // top: calc(50% - 22.2rem / 2);
      // padding: 2rem;
      width: 100%;

      .attemptCloseBtn {
        position: absolute;
        right: 12px;
        top: 12px;

        &:hover {
          cursor: pointer;
        }

        // @include respond(mobile) {
        //   right: calc(50% - 16.6rem);
        //   top: calc(50% - 5.5rem);
        // }
      }

      .reAttemptCloseBtn {
        position: absolute;
        right: 12px;
        top: 12px;

        &:hover {
          cursor: pointer;
        }

        // @include respond(mobile) {
        //   position: absolute;
        //   right: 12px;
        //   top: 12px;
        // }
      }

      .attemptHeader {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        color: $color_1;
        margin: 1rem;
      }

      .attempts {
        border-top: 2px solid $color_7;
        cursor: pointer;

        .attemptsText {
          font-family: $font-family_1;
          font-style: normal;
          font-size: 1.4rem;
          font-weight: 400;
          margin: 1rem;
          color: $color_9;
        }
      }
    }
  }

  .attemptsPopupHidden {
    display: none;
  }
}

.btnPrimary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 9px 16px;
  background: #423dcf !important;
  width: 160px;
  height: 40px;
  padding: 0px !important;
  border-radius: 10px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-size: 13px !important;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #fcfcfc;
  cursor: pointer;
  margin: auto;
  @include respond(mobile) {
    width: 140px;
  }
}
.btnPrimaryOutlined {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // padding: 9px 16px;
  border: #a6a0a0 1px solid;
  width: 160px;
  height: 40px;
  border-radius: 10px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-size: 13px !important;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #a6a0a0 !important;
  cursor: pointer;

  @include respond(mobile) {
    width: 140px;
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 100%;
  overflow: hidden;

  &.withoutTitle {
    gap: 20px;
  }

  .headerContentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    .headerContentTitle {
      display: block;
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 10px;
    }

    .headerContent {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #666060;
      overflow: hidden;

      @include respond(mobile) {
        &:not(.isExpanded) {
          max-height: 96px;
        }
      }

      + .showMoreContainer {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 42px;
        background: linear-gradient(to bottom, transparent 0%, #ffffff 35%, #ffffff 100%);
        align-items: flex-end;
        justify-content: center;

        button {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          font-weight: 800;
          color: #423dcf;
          cursor: pointer;
          column-gap: 6px;
        }
      }

      @include respond(mobile) {
        &.canHaveReadMore + .showMoreContainer {
          display: flex;
        }

        &.isExpanded + .showMoreContainer {
          position: static;
          height: fit-content;
        }
      }
    }
  }
}

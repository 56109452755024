@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.ldSpinner {
  color: #a996ff;
  display: inline-block;
  position: relative;
  width: 72px;
  height: 72px;
}

.ldSpinner div {
  transform-origin: 40px 40px;
  animation: ldSpinner 1.2s linear infinite;
}

.ldSpinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 7.2px;
  height: 21.6px;
  border-radius: 3.6px;
  background: #a996ff;
}

.ldSpinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9s;
}

.ldSpinner div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -0.8s;
}

.ldSpinner div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.7s;
}

.ldSpinner div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.6s;
}

.ldSpinner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.ldSpinner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.4s;
}

.ldSpinner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.3s;
}

.ldSpinner div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: -0.2s;
}

@keyframes ldSpinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.supportPopoverWrapper {
  position: fixed;
  bottom: 24px;
  right: 24px;
  height: 64px;
  width: 64px;
  z-index: 10;

  @include respond(tablet) {
    height: 48px !important;
    width: 48px !important;
  }

  .supportModalBtn {
    display: inline-block;

    @include respond(tablet) {
      height: 48px !important;
      width: 48px !important;

      &:not(.isOpened) svg {
        transform: scale(.75) translate(-8px, -8px);
      }
    }

    &.isOpened {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #a996ff;
      height: 62px;
      width: 62px;
      border-radius: 32px;
      font-size: 32px;
      color: white;
      border: 1px solid #423dcf;
    }
  }

  .popoverWrapper {
    position: absolute;
    bottom: 50px;
    right: 0;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #fff;
    padding: 16px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    max-width: 360px;
    width: calc(100vw - 48px);
    opacity: 0;
    visibility: hidden;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;

    &.showPopover {
      opacity: 1;
      visibility: visible;
      bottom: 85px;

      @include respond(tablet) {
        bottom: 60px;
      }
    }

    .popoverHeader {
      display: grid;
      grid-template-columns: 1fr max-content;
      gap: 30px;
      margin-bottom: 28px;

      .popoverHeaderTitle {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        gap: 12px;

        h3 {
          color: #2f2f2f;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%;
        }

        p {
          color: #4e4b66;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }

      .popoverHeaderImg {
        flex: 1 0 100%;
        height: 86px;
      }
    }

    .supportButton {
      display: flex;
      padding: 14px 25px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      color: #fcfcfc;
      font-size: 14px;
      font-weight: 800;
      line-height: 120%;
      background-color: #423dcf;

      &:disabled {
        background-color: #bfbefc;
        color: #6e7191;
      }
    }

    .contactIconsWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      gap: 4px;
      color: #6e7191;
      font-size: 12px;
      line-height: 16px;
      height: 24px;

      .contactPhoneWrapper {
        display: inline-flex;
        gap: 4px;
        align-items: center;
        border-right: 1px solid #a996ff;
        padding-right: 4px;
        height: 100%;

        .contactPhoneNumber {
          color: #423dcf;
          font-weight: 800;
          height: 14px;
        }
      }

      .whatsappWrapper {
        display: inline-flex;
        align-items: center;
        height: 100%;
        gap: 4px;
        cursor: pointer;

        .whatsappIcon {
          background: linear-gradient(#5dfa79 0%, #08b926 100%);
          font-size: 9px;
          display: inline-flex;
          height: 12px;
          width: 12px;
          border-radius: 3px;
          color: #ffffff;
          justify-content: center;
          align-items: center;
        }

        b {
          color: #423dcf;
          font-weight: 500;
          height: 14px;
        }
      }
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.languageDropdown {
  position: relative;
  display: block;
  margin-left: auto;
  width: 100%;
  max-width: 234px;

  .langugaeDropdownBottom {
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate3d(0px, 40px, 0px);
  }

  &::before {
    content: '';
    position: absolute;
    display: none;
    bottom: -7px;
    left: 0;
    height: 12px;
    width: 100%;
    background-color: #ffffff;
    z-index: 5;
  }

  .languageDropdownMenu {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 8px 24px;
    border-radius: 8px 8px 8px 8px;
    color: #5344c1;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    width: 100%;
    border: none !important;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
    z-index: 3;

    &::after {
      display: none;
    }
  }
  .languageDropdownOpen {
    border-radius: 8px 8px 0px 0px !important;
  }

  .dropdownMenu {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    clip-path: inset(0px -8px -8px -8px);
    border-radius: 0px 0px 8px 8px;
    padding: 10px 24px;
    width: 100%;
    border: none;
    background-color: #ffffff;
    position: absolute;
    font-size: 14px;
    line-height: 26px;
    z-index: 10;

    .dropdownItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      color: rgba(20, 20, 43, 1);
      cursor: pointer;
      font-weight: 500;
      padding: 8px 11px 8px 12px;

      .active {
        background-color: #ebecfe;
        border-radius: 4px;
        color: rgba(83, 68, 193, 1) !important;
      }

      &:hover {
        color: rgba(66, 61, 207, 1) !important;
      }
      &.active {
        background-color: #ebecfe;
        border-radius: 4px;
        font-weight: 900;
        color: #5344c1 !important;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    .dropDownItemText {
      margin-left: 10px;
    }
  }

  &.show {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    &::before {
      display: block;
    }
  }
}

@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.container {
  @include fwcontainer;
}

.careerWrapper{
  font-family: avenir;
  .heading_main{
    color: #ffbb23;
    // background-color: #36428d;
    background: url(../../assets/images/informatics/header-bg.jpg);
    // height: 500px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 35px;
    padding: 50px;
    @include respond(mobile) {
      padding: 35px;
      font-size: 28px;
    }
  }
  .midContainer_career{
 display: grid;
 grid-template-columns: 1fr 1fr;
 @include respond(mobile) {
  grid-template-columns: 1fr;
}
 padding: 20px 0 60px;
  }

.job_post_wrapper{
  // max-width: 500px;
  border-bottom: 2px solid #e7e8e9;
  &:nth-child(odd) {
    border-right: 2px solid #e7e8e9;
  }
  .job_post {
    padding: 35px 25px 30px 27px;
  }
  .job_post_link {
    text-decoration: none !important;

    &:hover .job_post_title {
        color: #000000 !important;
    }
  }
  .job_post_banner_wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 5px;

    .job_post_banner {
        width: 100%;
    }
  }
  .job_post_title {
    color: #f56019;
    text-transform: initial;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    line-height: 21px;
    margin-bottom: 0;
    transition: all 0.3s linear;
  }
  .job_post_actions {
    margin-top: 25px;

    .btn.btn_orange {
        background-color: #f56f2c;
        color: #ffffff;
        padding: 15px 40px;
        line-height: 1;
        font-size: 16px;
        font-weight: 700;
        transition: all 0.3s linear;

        &:hover {
            background-color: #2e3b8d;
            border-color: #2e3b8d;
        }
    }
  }
}
}

.aboutUsWrapper{
  font-family: avenir;
  .heading_main{
    color: #ffbb23;
    background: url(../../assets/images/informatics/header-bg.jpg);
    // height: 500px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 35px;
    padding: 50px;
    @include respond(mobile) {
      padding: 35px;
      font-size: 28px;
    }


  }
  .midContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
    text-align: center;
    padding: 20px 0 0px;
  }
  .each_Data{
    .title_name{
      h2{
        color: #ff9c00;
        font-family: "Quicksand" !important;
        font-size: 3rem;
        margin-top: 0;
        margin-bottom: 0.5em;
        font-weight: 500;
      }
    }
    .describe{
      margin-bottom: 60px;
      line-height: 32px;
      padding: 0 120px;
      @include respond(mobile) {
        padding: 0 30px;
      }
      font-size: 18px;
      color: #585858;
    }
  }
}